import {all, call, put, takeLatest} from 'redux-saga/effects'
import actions from './actions';
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest} from '../../config/axiosClient'

// function* setSnackbar(action) {

// }



export default function* rootSaga() {
  // yield all([takeLatest(actions.SNACKBAR_SET, setSnackbar)]);
}
