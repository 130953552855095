import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import createReducer from './rootReducers';
import rootSaga from './rootSaga';
import { createWrapper } from 'next-redux-wrapper';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

const initStore = (preloadedState) => {
  const sagaMiddleware = createSagaMiddleware();

  // Conditionally add redux-state-sync middleware if needed
  const middlewares = [
    ...getDefaultMiddleware({ thunk: false }), // Disable redux-thunk if not needed
    sagaMiddleware,
    // Uncomment and handle cart issues if necessary
    // createStateSyncMiddleware({
    //   blacklist: ['cart'] // Example: blacklist cart to avoid sync issues
    // }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: middlewares,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
  });

  // Keep track of async reducers
  store.asyncReducers = {};

  // Add injectReducer function to dynamically inject reducers
  store.injectReducer = (key, reducer) => {
    if (!store.asyncReducers[key]) {
      store.asyncReducers[key] = reducer;
      store.replaceReducer(createReducer(store.asyncReducers));
    }
  };

  // Add injectSaga function to dynamically inject sagas
  store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

  // Initialize redux-state-sync message listener
  initMessageListener(store);

  // Enable Hot Module Replacement (HMR) for reducers in development
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducers', () => {
      const newRootReducer = require('./rootReducers').default;
      store.replaceReducer(newRootReducer);
    });
  }

  return store;
};

// Function to inject sagas dynamically
function createSagaInjector(runSaga, rootSaga) {
  const injectedSagas = new Map();

  const isInjected = (key) => injectedSagas.has(key);

  const injectSaga = (key, saga) => {
    if (isInjected(key)) return; // Don't inject the same saga more than once

    const task = runSaga(saga); // Run the saga and store the task
    injectedSagas.set(key, task);
  };

  // Inject the root saga (this will always be loaded)
  injectSaga('root', rootSaga);

  return injectSaga;
}

// Create the Redux wrapper for Next.js
export const wrapper = createWrapper(initStore, {
  // debug: process.env.NODE_ENV !== 'production',
});
