const actions = {
  GET_JOBS: 'GET_JOBS',
  GET_JOBS_SUCCESS: 'GET_JOBS_SUCCESS',
  GET_JOBS_FAILURE: 'GET_JOBS_FAILURE',
    
  //SINGLES
  SET_PAGE_SPINNER: 'SET_PAGE_SPINNER',


}

export default actions;
