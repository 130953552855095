import actions from '../Stripe/actions'

const initialState = {
    paymentIntent: {
        clientSecret: null,
    },
    key: null,
    payment_error: null,
};

function Reducer(state = initialState, action) {
    switch (action.type) {
        case actions.PAYMENT_INTENT_SUCCESS:
            return { 
                ...state, 
                quoteData: action.payload.data,
                paymentIntent: {
                    clientSecret: action.payload.secret.clientSecret
                },
                stripeLoader: false
        };

        case actions.PAYMENT_INTENT:
            return {
                ...state,
                paymentIntent: {
                    clientSecret: null,
                },
        };

        case actions.CLEAR_PAYMENT_INTENT:
            return {
                ...state,
                paymentIntent: {
                    clientSecret: null,
                },
        };

        case actions.INIT_STRIPE:
            return {
                ...state,
                key: null,
                stripeLoader: true,
        };
        case actions.SUBMIT_STRIPE_KEY:
            return {
                ...state,
                key: action.payload
        };
        case actions.SET_PAYMENT_ERROR:
            return {
                ...state,
                payment_error: action.payload
        };

        case actions.STRIPE_RESET:
            return initialState;

        default:
            return state;
    }
}

export default Reducer;
