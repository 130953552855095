import { all, call, put, select, takeLatest } from "redux-saga/effects";
import actions from "../UserJourney/actions";
import {
    postRequest,
    postBaseRequest,
} from "../../config/axiosClient";
import CacheFactory from "../../Factories/CacheFactory";

export const getUserJourneyReducer = (state) => state.userjourneyReducer;

function* userJourneySave(action) {
    //create user journey using api
    try {
        const response = yield call(() =>
            postBaseRequest("create-user-journey", action.payload)
        );
        if (response.data.success == true) {
            yield put({
                type: actions.USERJOURNEY_SAVE_DATA_SUCCESS,
                payload: response.data.userJourney,
            });
        } else {
            throw response.data.message;
        }
    } catch (error) {
        // console.log(error);
        yield put({
            type: actions.USERJOURNEY_SAVE_DATA_FAILURE,
            payload: error,
        });
    }
    //save in the cache to retrieve when the page is refreshed
    yield call(updateCache);
}
function* userJourneyEntrySave(action) {
    const reducer = yield select(getUserJourneyReducer);
    const userJourney = reducer.userJourney;
    try {
        const response = yield call(() =>
            postRequest("create-user-journey-entry", action.payload)
        );
        let userJourneyTracking = userJourney.tracking;
        userJourneyTracking = [...userJourneyTracking, action.payload.url]; //insert the new product into the cart without mutating
        yield put({
            type: actions.USERJOURNEY_ENTRY_SAVE_DATA_SUCCESS,
            payload: userJourneyTracking,
        });
    } catch (error) {
        yield put({
            type: actions.USERJOURNEY_ENTRY_SAVE_DATA_FAILURE,
            payload: error,
        });
    }
    yield call(updateCache);
}

function* userJourneySet(action) {
    try {
        yield put({
            type: actions.USERJOURNEY_SET_SUCCESS,
            payload: action.payload,
        });
    } catch (error) {
        yield put({ type: actions.USERJOURNEY_SET_FAILURE, payload: error });
    }
}

function* updateCache() {
    const reducer = yield select(getUserJourneyReducer);
    const userJourney = reducer.userJourney;
    CacheFactory.put("userJourney", userJourney); //insert the current state into the cache
}

export default function* rootSaga() {
    yield all([takeLatest(actions.USERJOURNEY_SAVE_DATA, userJourneySave)]);
    yield all([takeLatest(actions.USERJOURNEY_SET, userJourneySet)]);
    yield all([
        takeLatest(actions.USERJOURNEY_ENTRY_SAVE_DATA, userJourneyEntrySave),
    ]);
}
