import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from './actions'
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postCustomRequest, getRootReq, postRootReq, getBaseRequest, postBaseRequest} from '../../config/axiosClient'
import axios from 'axios';

import {topMiddleErrorAlert, topMiddleSuccessAlert} from '../Alert/apiSaga';

export const generic_error_message = 'Oops, something went wrong!';
export const getDash = (state) => state.dashboardReducer;
export const getAuth = (state) => state.authReducer;


function* getSingleOrderSectionDetails(action) {
    const {
    order_id,
    } = action.payload

    const queryString = `dashboard/order/get-order-details?order_id=${order_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_ORDER_SECTION_DETAILS_SUCCESS, payload: response.data})
  
    } catch (error) {
        console.log(error)
    //   yield put({type: actions.GET_SINGLE_ORDER_SECTION_DETAILS_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* getSingleOrderSectionItems(action) {
    const {
    order_id,
    } = action.payload

    const queryString = `dashboard/order/get-order-items?order_id=${order_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_ORDER_SECTION_ITEMS_SUCCESS, payload: response.data})
  
    } catch (error) {
    //   yield put({type: actions.GET_SINGLE_ORDER_SECTION_ITEMS_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  

function* getSingleOrderSectionCharges(action) {
    const {
    order_id,
    } = action.payload

    const queryString = `dashboard/order/get-order-charges?order_id=${order_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_ORDER_SECTION_CHARGES_SUCCESS, payload: response.data})
  
    } catch (error) {
    //   yield put({type: actions.GET_SINGLE_ORDER_SECTION_CHARGES_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  

function* getSingleOrderSectionManagement(action) {
    const {
    order_id,
    } = action.payload

    const queryString = `dashboard/order/get-order-management?order_id=${order_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_ORDER_SECTION_MANAGEMENT_SUCCESS, payload: response.data})
  
    } catch (error) {
    //   yield put({type: actions.GET_SINGLE_ORDER_SECTION_MANAGEMENT_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* handlePayment(action) {

  const {
    order,
    ...rest
  } = action.payload;


  try {

    yield put({type: actions.ORDER_UPDATE_PAYMENT_STATUS, payload: {
      loading: true,
      make_payment: true,
      update_order: true,
    }})

    const response = yield call(() => postBaseRequest('dashboard/order/finalise-order-payment', {order: order, ...rest}) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }


    if(response.data.status == 'success'){
      const data = response.data.data;

      yield put({type: actions.ORDER_UPDATE_PAYMENT_STATUS, payload: {
        loading: false,
        make_payment: true,
        update_order: true,
        message: response.data.message,
        complete: true,
        error: false,
      }})

      yield put({type: actions.DASHBOARD_ORDER_HANDLE_PAYMENT_SUCCESS, payload: response.data})

    }
    else {
      yield call(paymentProcessError, response.data.message)
    }


  } catch (error) {
    console.log(error)
    yield call(paymentProcessError, generic_error_message)
  }
}


function* paymentProcessError(error) {
  yield put({type: actions.DASHBOARD_ORDER_HANDLE_PAYMENT_FAILURE});
  yield call(topMiddleErrorAlert, error)
  yield put({type: actions.ORDER_UPDATE_PAYMENT_STATUS, payload: {
    make_payment: false,
    create_order: false,
    create_carriage: false,
    loading: false,
    error: true
  }})
}

function* getEmailLogs(action) {
  const {type, order_id} = action.payload;
  const queryString = `agent/get-email-logs?type=${type}&order_id=${order_id}`;

  try {
    const response = yield call(() => getBaseRequest(queryString)); 
    yield put({type: actions.ORDER_GET_EMAIL_LOG_SUCCESS, payload: response.data})

    if(response.data.status == 'failed'){
      yield put({type: actions.ORDER_GET_EMAIL_LOG_FAILURE, payload: response.data})
    }

  } catch (error) {
  //   yield put({type: actions.FETCH_LOST_QUOTE_REASONS_FAILURE, payload: response.data})
    // yield call(topMiddleErrorAlert, generic_error_message)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_SINGLE_ORDER_SECTION_DETAILS, getSingleOrderSectionDetails),
    takeLatest(actions.GET_SINGLE_ORDER_SECTION_ITEMS, getSingleOrderSectionItems),
    takeLatest(actions.GET_SINGLE_ORDER_SECTION_CHARGES, getSingleOrderSectionCharges),
    takeLatest(actions.GET_SINGLE_ORDER_SECTION_MANAGEMENT, getSingleOrderSectionManagement),
    takeLatest(actions.DASHBOARD_ORDER_HANDLE_PAYMENT, handlePayment),
    takeLatest(actions.ORDER_GET_EMAIL_LOG, getEmailLogs),
  ]);
}
