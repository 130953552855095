import {all, call, put, takeLatest} from 'redux-saga/effects'
import actions from '../Contact/actions';
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest} from '../../config/axiosClient'

function* contact(action) {
  try {
    const response = yield call(() => postRequest('message', action.payload.form)); //api request the send the contact message
    
    if(action.payload.source != 'contact_page'){
        yield put({type: 'SNACKBAR_SET', payload: { //snackbar to display that a contact message has been sent
            open: true,
            title: 'Contact message successfully sent!',
            variant: 'success',
        }});
    }
    else {
      yield put({type: 'ALERT_SET', payload: { //snackbar to display that a contact message has been sent
          open: true,
          title: 'Contact message successfully sent!',
          severity: 'success',
          variant: 'filled',
          duration: 3000,
          vertical: 'top',
          horizontal: 'center'
      }});
    }
    yield put({type: actions.CONTACT_SEND_MESSAGE_SUCCESS, payload: response.data});
    //TODO: google tracking
  } catch (error) {
    yield put({type: actions.CONTACT_SEND_MESSAGE_FAILURE, payload: error});
    yield put({type: 'SNACKBAR_SET', payload: { //snackbar to display that a contact message has been sent
      open: true,
      title: 'Contact message could not be sent!',
      variant: 'danger',
    }});
  }

}



export default function* rootSaga() {
  yield all([takeLatest(actions.CONTACT_SEND_MESSAGE, contact)]);
}
