/*
|--------------------------------------------------------------------------
| Cache Factory
|--------------------------------------------------------------------------
| Basic way as to using local storage as cache. Similar functions in the way we used
| AngularJS, but not as advanced. We can amend this approach as we advance. 
| To use import CacheFactory and call the function e.g - CacheFactory.get(key)
*/

let CacheFactory = null;

const defaultTime = 1000 * 60 * 60; // 1 hour

if (typeof window != 'undefined') {
    CacheFactory = {
        has: (key, useInfinity = false) => {
            // Use get method to check if the item exists and is not expired
            const time = useInfinity ? Infinity : defaultTime;
            return CacheFactory?.get(key, time) != null;
        },
        get: (key, time = defaultTime) => {
            const itemExists = localStorage.getItem(key);
            if (!itemExists) return;
            const item = JSON.parse(itemExists);
            const now = new Date();

            // If item has an expiry and it's expired, remove it
            if (item.expiry && now.getTime() > item.expiry) {
                localStorage.removeItem(key);
                return null;
            }

            // Update expiry time if a new time is provided and it's not unlimited
            if (time !== Infinity) {
                item.expiry = now.getTime() + time;
                localStorage.setItem(key, JSON.stringify(item));
            }

            return item.value;
        },
        put: (key, value, time = defaultTime) => {
            const now = new Date();
            const item = {
                value: value,
                expiry: time === Infinity ? null : now.getTime() + time, // No expiry if time is Infinity
                created: now.getTime()
            }
            localStorage.setItem(key, JSON.stringify(item));
        },
        remove: (key) => {
            localStorage.removeItem(key);
        },
        clear: () => {
            localStorage.clear();
        }
    }
}

export default CacheFactory;
