const actions = {
  CHECKOUT_QUOTE_SET_QUOTE: "CHECKOUT_QUOTE_SET_QUOTE",
  CHECKOUT_QUOTE_RESET: "CHECKOUT_QUOTE_RESET",
  CHECKOUT_QUOTE_SET_KEY: "CHECKOUT_QUOTE_SET_KEY",
  CHECKOUT_QUOTE_SET_LOADER: "CHECKOUT_QUOTE_SET_LOADER",
  UPDATE_QUOTE_OBJECT_KEY: "UPDATE_QUOTE_OBJECT_KEY",
  CHECKOUT_QUOTE_RESET: "CHECKOUT_QUOTE_RESET"
}

export default actions;
