import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl  from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';

const Field = styled(TextField)(({ theme }) => ({
    background: 'white',
    borderRadius: '8px',
    ':hover':{
      borderColor :   theme.palette.primary.main,
    },
    '& .MuiFormControl-root-MuiTextField-root':{
      borderColor :   theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: "#ccc", 
    },
    '& .MuiInputLabel-shrink': {
        color: "rgb(0 0 0 / 80%)",
    },
    '& .MuiInputLabel-root .MuiInputLabel-shrink': {
        color: "#000", 
    },
    '& .Mui-focused MuiInputLabel-shrink': {
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      '& .Mui-Focused .MuiInputBase-input': {
        color: "rgb(0 0 0 / 80%)",
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ccc'
    },
    '&:hover $notchedOutline': {
      borderColor:  theme.palette.primary.main,
    },
    '&.MuiInputBase-multiline': {
      padding: 1
    },
    "&  .MuiFormHelperText-root.Mui-error": { //<--- here
      // backgroundColor: "red",
      margin:0,
      paddingLeft: 10
    },
    '& .MuiFormControl-root': {
      fontSize: {
        xs: 7,
        lg: 15
      }
    },
    '& .Mui-disabled': {
      backgroundColor: '#e9ecef',
      cursor: 'not-allowed',
      pointerEvents: 'all',
      ':hover': {
        backgroundColor: '#e9ecef',
      }
    },
    '& .MuiInputBase-readOnly': {
      backgroundColor: '#e9ecef',
      cursor: 'not-allowed',
      pointerEvents: 'all',
      ':hover': {
        backgroundColor: '#e9ecef',
      }
    },
    input:  {
      fontWeight: 500,
      background: 'white',
      padding: '15.5px 14px',
      borderRadius: '8px',
    },
    inputAdornedStart: {
      paddingLeft: 4
    },
    notchedOutline: {
    }
}));

const inputSx = {
  input: {
    '& .MuiInputBase-inputSizeSmall': {
      padding: '8.5px 14px',
      ' &.MuiInputBase-inputAdornedStart': {
        paddingLeft: 0
      }
    },
    '& .MuiInputBase-inputSizeSmall.xs': {
      padding: '2.5px 14px',
      ' &.MuiInputBase-inputAdornedStart': {
        paddingLeft: 0
      }
    }
  }
}

const FormLabelStyled = styled(FormLabel)({
    padding: '4px',
    fontWeight: 600,
    color: '#000',
    fontSize: {
      xs: 7,
      lg: 15
    }
});

const TextInput = (props) => {
    const {
        fullWidth = true,
        labelAbove = false,
        labelLeft = false,
        gridProps = {
            left: {
              xs: 12,
              md: 6
            },
            right: {
              xs: 12,
              md: 6
            }
        },
        formControlClasses = "",
        formControlSx = {},
        helperText,
        helperTextSx = {},
        labelSx = {},
        sx = {},
        InputProps = {},
        forceHelper = false,
        clear = false,
        size = 'small', // default size
        ...other
    } = props;
    

    const [touched, setTouched] = useState(false);

    const onBlur = (e) => {
        setTouched(true);
    }

    const handleClear = () => {
        const name = props?.name;
        props?.onChange({
          target: {name, value: ''}
        })
    }

    const clearProps = clear && props?.value?.length > 0 ? {
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton
            aria-label="clear-text"
            onClick={handleClear}
            edge="end"
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      )
    } : {};

    const InputButton = () => (
      <Field
          InputProps={{
            ...InputProps,
            sx: {
              ...inputSx.input, 
              ...sx,
              ...(size === 'xs' && {
                '& .MuiInputBase-input': {
                  padding: '2.5px 14px',
                }
              })
            },
            ...clearProps
          }}
          onBlur={onBlur}
          {...other}
          size={size} // pass the size prop to TextField
          label={labelAbove || labelLeft ? null : props.label}
          InputLabelProps={{
              shrink: props.labelAbove || props.labelLeft && false,
          }}
          autoComplete='off'
      />
    )

    return (
        <>
        <FormControl fullWidth={fullWidth} sx={{
          ...formControlSx,
          '& .MuiFormLabel-root': {
            fontSize: {
              xs: 7,
              lg: 15
            }
          }
        }} className={formControlClasses}
        >

            {labelAbove && <FormLabelStyled sx={labelSx}>{`${props.label} ${props.required ? '*' : ''}`}</FormLabelStyled>}

            {labelLeft ? (
              <>
                <Grid container>
                  <Grid className="vertical-auto" item {...gridProps.left}>
                    <Typography variant="strong" component="strong" >{props.label}</Typography> 
                  </Grid>
                  <Grid item {...gridProps.right}>
                    {InputButton()}
                  </Grid>
                </Grid>
              </>
            ) : (
              InputButton()
            )}

              {
                (props.error || forceHelper) && (
                    <FormHelperText 
                          sx={{
                            fontSize: 14,
                            ...helperTextSx,
                          }}
                          error={props.error}
                        >
                        {helperText}
                    </FormHelperText>
                )
              }

              {
                props?.required && props?.value == "" && touched && (
                  <FormHelperText 
                      sx={{
                        fontSize: 14,
                        ...helperTextSx,
                      }}
                      error={true}
                    >
                      This field is required
                </FormHelperText>
                )
              }
        </FormControl>
        </>
    )
}

export default TextInput;
