import actions from '../User/actions';

const initialState = {
    user_agent: null,
    ip: null,
    token: null,
}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.USER_INITIALIZE_DATA:
      return {
        ...state, userLoaders: {...state.userLoaders, init: true },
    }
    case actions.USER_INITIALIZE_DATA_SUCCESS:
      return {
        ...state, 
        ...action.payload,
        userLoaders: { ...state.userLoaders, init: false },
    }
    case actions.USER_INITIALIZE_DATA_FAILURE:
      return {
        ...state, userLoaders: { ...state.userLoaders, init: false },
    }

    case actions.SET_TOKEN:
      return {
        ...state, userLoaders: {...state.userLoaders, settingToken: true },
    }
    case actions.SET_TOKEN_SUCCESS:
      return {
        ...state, 
        token: action.payload,
        userLoaders: { ...state.userLoaders, settingToken: false },
    }
    case actions.SET_TOKEN_FAILURE:
      return {
        ...state, 
        token: action.payload,
        userLoaders: { ...state.userLoaders, settingToken: false },
    }
    default:
      return state
  }
}

export default Reducer;
