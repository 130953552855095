import {all, call, put, takeLatest} from 'redux-saga/effects'
import actions from './actions';
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest} from '../../config/axiosClient'

// function* setSnackbar(action) {

// }

const defaultDuration = 3000;

export function* topMiddleSuccessAlert(message) {
    yield put({type: 'ALERT_SET', payload: { //snackbar to display that a contact message has been sent
      open: true,
      title: message,
      severity: 'success',
      variant: 'filled',
      duration: 3000,
      vertical: 'top',
      horizontal: 'center'
  }});
}

export function* topMiddleWarningAlert(message) {
    yield put({type: 'ALERT_SET', payload: { //snackbar to display that a contact message has been sent
      open: true,
      title: message,
      severity: 'warning',
      variant: 'filled',
      duration: 3000,
      vertical: 'top',
      horizontal: 'center'
  }});
}

export function* topMiddleErrorAlert(message) {
    yield put({type: 'ALERT_SET', payload: { //snackbar to display that a contact message has been sent
      open: true,
      title: message,
      severity: 'error',
      variant: 'filled',
      duration: 3000,
      vertical: 'top',
      horizontal: 'center'
  }});
}

export function* topMiddleInfoAlert(message) {
    yield put({type: 'ALERT_SET', payload: { //snackbar to display that a contact message has been sent
      open: true,
      title: message,
      severity: 'info',
      variant: 'filled',
      duration: 3000,
      vertical: 'top',
      horizontal: 'center'
  }});
}

export function* customAlert(props) {
    yield put({type: 'ALERT_SET', payload: { //snackbar to display that a contact message has been sent
        open: true,
        ...props,
    }});
}

function* handleTopMiddleErrorAlert(action) {
  yield call(topMiddleErrorAlert, action.payload)
}

function* handleTopMiddleWarningAlert(action) {
  yield call(topMiddleWarningAlert, action.payload)
}

function* handleTopMiddleSuccessAlert(action) {
  yield call(topMiddleSuccessAlert, action.payload)
}
function* handleTopMiddleInfoAlert(action) {
  yield call(topMiddleInfoAlert, action.payload)
}

function* handleCustomAlert(action) {
  yield call(customAlert, action.payload)
}


export default function* rootSaga() {
  yield all([takeLatest(actions.TOP_MIDDLE_ERROR_ALERT, handleTopMiddleErrorAlert)]);
  yield all([takeLatest(actions.TOP_MIDDLE_SUCCESS_ALERT, handleTopMiddleSuccessAlert)]);
  yield all([takeLatest(actions.TOP_MIDDLE_WARNING_ALERT, handleTopMiddleWarningAlert)]);
  yield all([takeLatest(actions.TOP_MIDDLE_INFO_ALERT, handleTopMiddleInfoAlert)]);
  yield all([takeLatest(actions.CUSTOM_ALERT, handleCustomAlert)]);
}
