import { produce } from 'immer';
import actions from '../../Checkout/Payment/actions';

const initialState = {
	countries: [],
	showBacs: false,
	hasPaid: false,
	readyForPayment: false,
	_paymentError: false,
	payment_message: "",
	paymentSucceeded: false,
	processingOrder: false,
	success: null,
	steps: {
		billing: true,
		payment: false,
	},
	vat_number: null,
	address: {
		billing: {
			lift: '',
			liftFit: '',
			floor: '',
			parkingRestrictions: 'None',
			additionalInfo: '',
			country: 'GB'
		},
	},
	craftyResults: {
		billing: {
			foundAddresses: [],
			selectedAddress: 'default',
			error: null
		}
	},
	paymentLoaders: {
		saveOrder: false,
		paymentInit: true,
	}
};

function Reducer(state = initialState, action) {
  switch (action.type) {

    case actions.CHECKOUT_PAYMENT_SET_PAYMENT:
		return {
			...state, 
			...action.payload
    }
    case actions.CHECKOUT_PAYMENT_SET_STEPS:
		return {
			...state, 
			steps: {
				...state.steps,
				...action.payload
			}
    }
    case actions.UPDATE_PAYMENT_OBJECT_KEY:
		return {
			...state, 
			[action.payload.key]: action.payload.value,
    	}
	case actions.CHECKOUT_PAYMENT_SET_LOADER:
		return produce(state, (draft) => {
			draft.paymentLoaders[action.payload.key] = action.payload.value;
		})

	case actions.CHECKOUT_PAYMENT_SET_KEY:
		return {
			...state,
			[action.payload.key]: {
				...state[action.payload.key],
				...action.payload.value
			}
		}

	case actions.PAYMENT_UPDATE_CRAFTY_RESULTS:
		return {
			...state, 
			craftyResults: {
			...state.craftyResults,
				billing: {
					...state.craftyResults.billing,
					foundAddresses: action.payload
				}
			},
		}

	case actions.PAYMENT_SET_CRAFTY_SELECTED_ADDRESS:
		console.log(action.payload)
		return {
			...state, 
			craftyResults: {
			...state.craftyResults,
				billing: {
					...state.craftyResults.billing,
					selectedAddress: action.payload
				}
			},
		}


	case actions.CHECKOUT_PAYMENT_GET_COUNTRIES: 
		return {
			...state,
			countries: [],
			paymentLoaders: {
				...state.paymentLoaders,
				getCountries: true
			}
		}
	case actions.CHECKOUT_PAYMENT_GET_COUNTRIES_SUCCESS: 
		return {
			...state,
			countries: action.payload,
			paymentLoaders: {
				...state.paymentLoaders,
				getCountries: false
			}
		}
	case actions.CHECKOUT_PAYMENT_GET_COUNTRIES_FAILURE: 
		return {
			...state,
			countries: null,
			paymentLoaders: {
				...state.paymentLoaders,
				getCountries: false
			}
		}
    case actions.CHECKOUT_PAYMENT_RESET:
      	return initialState;
    default:
      	return state
  }
}

export default Reducer;
