import CacheFactory from '../../Factories/CacheFactory';
import actions from '../Global/actions';

const initialState = {
    globalLoaders: [],
    templateSettings: {},
    breadcrumbs: [],
    pageSpinner: false,
    firstRender : true,
    VATFlag: false,
    tax: 20,
    sidenav: null,
    venue: null,
    show: null,
    venues: [],
    shows: []
}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.DEFAULT_DISPATCH:
      return {
        ...state, globalLoaders: {...state.globalLoaders },
    }
    case actions.DEFAULT_DISPATCH_SUCCESS:
      return {
        ...state, globalLoaders: { ...state.globalLoaders, dispatch: true },
    }
    case actions.DEFAULT_DISPATCH_FAILURE:
      return {
        ...state, globalLoaders: { ...state.globalLoaders },
    }

    case actions.GLOBAL_INIT_VARIABLES:
      return {
        ...state, 
        globalLoaders: {...state.globalLoaders, initVariables: true },
    }
    case actions.GLOBAL_INIT_VARIABLES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        firstRender: false,
        globalLoaders: { ...state.globalLoaders, initVariables: false },
    }
    case actions.GLOBAL_INIT_VARIABLES_FAILURE:
      return {
        ...state, 
        initError: action.payload,
        globalLoaders: { ...state.globalLoaders, initVariables: false },
    }

    case actions.GLOBAL_SET_GLOBAL_PROPS:
      return {
        ...state, globalLoaders: {...state.globalLoaders, setGlobalProps: true},
    }
    case actions.GLOBAL_SET_GLOBAL_PROPS_SUCCESS:
      return {
        ...state, 
        ...action.payload,
        globalLoaders: { ...state.globalLoaders, setGlobalProps: false },
    }
    case actions.GLOBAL_SET_GLOBAL_PROPS_FAILURE:
      return {
        ...state, 
        templateSettings: {},
        globalProps: {},
        globalLoaders: { ...state.globalLoaders, setGlobalProps: false },
    }

    case actions.GLOBAL_SET_KEY:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      }

    case actions.GLOBAL_GET_TEMPLATE_SETTINGS:
      return {
        ...state, globalLoaders: {...state.globalLoaders, fetchTemplateSettings: true},
    }
    case actions.GLOBAL_GET_TEMPLATE_SETTINGS_SUCCESS:
      return {
        ...state, 
        templateSettings: action.payload,
        globalLoaders: { ...state.globalLoaders, fetchTemplateSettings: false },
    }
    case actions.GLOBAL_GET_TEMPLATE_SETTINGS_FAILURE:
      return {
        ...state, 
        templateSettings: {},
        globalLoaders: { ...state.globalLoaders, fetchTemplateSettings: false },
    }

    case actions.GET_BRAND_ENVIRONMENT_DATA:
      return {
        ...state, globalLoaders: {...state.globalLoaders },
    }

    case actions.GET_BRAND_ENVIRONMENT_DATA_SUCCESS:
      return {
        ...state,
        environment: action.payload
        ,
    }
    case actions.GET_BRAND_ENVIRONMENT_DATA_FAILURE:
      return {
        ...state, globalLoaders: { ...state.globalLoaders },
        environment: null
    }

    case actions.SET_TRANSPORT_DETAILS:
      return {
        ...state, 
        globalLoaders: {...state.globalLoaders, updatingTransport: true, },
    }
    case actions.SET_TRANSPORT_DETAILS_SUCCESS:
      return {
        ...state, 
        transport: action.payload,
        globalLoaders: { ...state.globalLoaders, updatingTransport: false },
    }
    case actions.SET_TRANSPORT_DETAILS_FAILURE:
      return {
        ...state, 
        globalLoaders: { ...state.globalLoaders, updatingTransport: false },
    }


    case actions.DELIVERY_CHECK_MANDITORY_SERVICE:
      return {
        ...state, 

          globalLoaders: {
            ...state.globalLoaders, 
          checkingMandatoryService: true 
        },
    }

    case actions.DELIVERY_CHECK_MANDITORY_SERVICE_SUCCESS:
      return {
        ...state,
        serviceLock: action.payload.serviceLock,
        transport: {
          ...state.transport,
          delivery: {
            ...state.transport.delivery,
            ...(action.payload.transport.delivery ? action.payload.transport.delivery : state.transport.delivery)
          },
          collection: {
            ...state.transport.collection,
            ...(action.payload.transport.collection ? action.payload.transport.collection : state.transport.collection)
          },
        },
        globalLoaders: {
          ...state.globalLoaders,
          checkingMandatoryService: false
        }
    };
    case actions.DELIVERY_CHECK_MANDITORY_SERVICE_FAILURE:
      return {
        ...state,
        globalLoaders: {
          ...state.globalLoaders,
          checkingMandatoryService: false
        }
    }

    case actions.DELIVERY_SET_INSTALL_OR_DERIG:
      return {
        ...state, 
        globalLoaders: {...state.globalLoaders, setInstallDerig: true, },
    }
    case actions.DELIVERY_SET_INSTALL_OR_DERIG_SUCCESS:
      return {
        ...state, 
        transport: {
          ...state.transport,
          [action.payload.type]: {
            ...state.transport[action.payload.type],
            ...action.payload.data,
          }
        },
        globalLoaders: { ...state.globalLoaders, setInstallDerig: false },
    }
    case actions.DELIVERY_SET_INSTALL_OR_DERIG_FAILURE:
      return {
        ...state, 
        globalLoaders: { ...state.globalLoaders, setInstallDerig: false },
    }

    case actions.DELIVERY_UPDATE_TRANSPORT:
      return {
        ...state, 
        globalLoaders: {...state.globalLoaders, updatingTransport: true, },
    }
    case actions.DELIVERY_UPDATE_TRANSPORT_SUCCESS:
      return {
        ...state, 
        transport: {
          ...state.transport,
         [action.payload.type]: {
            ...state.transport[action.payload.type],
            option: action.payload.option,
            timeSlots: action.payload.timeSlots,
            timeStart: action.payload.timeStart,
            timeEnd: action.payload.timeEnd,
         }
        },
        globalLoaders: { ...state.globalLoaders, updatingTransport: false },
    }
    case actions.DELIVERY_UPDATE_TRANSPORT_FAILURE:
      return {
        ...state, 
        globalLoaders: { ...state.globalLoaders, updatingTransport: false },
    }

    case actions.SET_PAGE_CONTENT:
      return {
        ...state, 
        globalLoaders: {...state.globalLoaders, updatingTransport: true, },
    }
    case actions.SET_PAGE_CONTENT_SUCCESS:
      return {
        ...state, 
        content: {
          ...state.page_content,
          ...action.payload
        },
        globalLoaders: { ...state.globalLoaders, updatingTransport: false },
    }
    case actions.SET_PAGE_CONTENT_FAILURE:
      return {
        ...state, 
        globalLoaders: { ...state.globalLoaders, updatingTransport: false },
    }



    //SINGLES
    case actions.SET_PAGE_SPINNER:
      return {
        ...state, 
        pageSpinner: action.payload,
    }

    case actions.GLOBAL_SWITCH_VAT_TOGGLE:
    CacheFactory.put('VATFlag', !state.VATFlag)
      return {
        ...state, globalLoaders: { ...state.globalLoaders },
        VATFlag: CacheFactory.get('VATFlag')
    }
    case actions.GLOBAL_SET_TAX:
      return {
        ...state,
        tax: action.payload,
    }
    case actions.SET_HIRE_DATE_RANGE:
      return {
        ...state,
        ...action.payload
    }
    case actions.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload
    }
    case actions.SET_SIDENAV:
      return {
        ...state,
        sidenav: action.payload
    }
    case actions.SET_GLOBAL_DATA:
      return {
        ...state,
        ...action.payload
    }
    default:
      return state
  }
}

export default Reducer;
