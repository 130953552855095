import actions from '../Contact/actions';

const initialState = {
    contactLoaders: [],
}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.CONTACT_SEND_MESSAGE:
      return {
        ...state, contactLoaders: {...state.contactLoaders, submitContactForm: true },
    }
    case actions.CONTACT_SEND_MESSAGE_SUCCESS:
      return {
        ...state, 
        contactMessage: {
          ...action.payload,
          sent: true
        },
        contactLoaders: { ...state.contactLoaders, submitContactForm: false },
    }
    case actions.CONTACT_SEND_MESSAGE_FAILURE:
      return {
        ...state, 
        contactMessage: {
          sent: false
        },
        contactLoaders: { ...state.contactLoaders, submitContactForm: false },
    }

    default:
      return state
  }
}

export default Reducer;
