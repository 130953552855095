import actions from '../PayPal/actions'

const initialState = {
    key: null,
};

function Reducer(state = initialState, action) {
    switch (action.type) {

        case actions.INIT_PAYPAL:
            return {
                ...state,
                key: {}
        };
        case actions.INIT_PAYPAL_SUCCESS:
            return {
                ...state,
                key: action.payload
        };
        case actions.PAYPAL_RESET:
            return initialState;

        default:
            return state;
    }
}

export default Reducer;
