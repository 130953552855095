import { all, call, put, takeLatest, select } from "redux-saga/effects";
import actions from "./actions";
import {
    postRequest,
    postBaseRequest,
    getCustomRequest,
    getRequest,
    deleteRequest,
    getBaseRequest,
} from "../../config/axiosClient";


function* getPayPalKey(action) {
    try {
        const response = yield call(() =>
            getBaseRequest("init-paypal")
        );

        if (response.data) {
            yield put({
                type: actions.INIT_PAYPAL_SUCCESS,
                payload: response.data.key,
            });
        } else {
            yield put({
                type: actions.INIT_PAYPAL_SUCCESS,
                payload: null,
            });
        }
    } catch (error) {
        if (error.response.status === 401) {
            message.error(error.response.data.message);
        } else {
            message.error("Something Went Wrong");
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest("INIT_PAYPAL", getPayPalKey),
    ]);
}
