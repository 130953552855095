const actions = {

    SETUP_DASHBOARD_DATA: 'SETUP_DASHBOARD_DATA',
    SETUP_DASHBOARD_DATA_SUCCESS: 'SETUP_DASHBOARD_DATA_SUCCESS',
    SETUP_DASHBOARD_DATA_FAILURE: 'SETUP_DASHBOARD_DATA_FAILURE',

    SETUP_DASHBOARD: 'SETUP_DASHBOARD',
    SETUP_DASHBOARD_SUCCESS: 'SETUP_DASHBOARD_SUCCESS',
    SETUP_DASHBOARD_FAILURE: 'SETUP_DASHBOARD_FAILURE',

    SETUP_DASHBOARD_DETAILS: 'SETUP_DASHBOARD_DETAILS',
    SETUP_DASHBOARD_DETAILS_SUCCESS: 'SETUP_DASHBOARD_DETAILS_SUCCESS',
    SETUP_DASHBOARD_DETAILS_FAILURE: 'SETUP_DASHBOARD_DETAILS_FAILURE',

    SETUP_DASHBOARD_QUOTES: 'SETUP_DASHBOARD_QUOTES',
    SETUP_DASHBOARD_QUOTES_SUCCESS: 'SETUP_DASHBOARD_QUOTES_SUCCESS',
    SETUP_DASHBOARD_QUOTES_FAILURE: 'SETUP_DASHBOARD_QUOTES_FAILURE',

    SETUP_DASHBOARD_ORDERS: 'SETUP_DASHBOARD_ORDERS',
    SETUP_DASHBOARD_ORDERS_SUCCESS: 'SETUP_DASHBOARD_ORDERS_SUCCESS',
    SETUP_DASHBOARD_ORDERS_FAILURE: 'SETUP_DASHBOARD_ORDERS_FAILURE',

    SETUP_DASHBOARD_ADDRESSES: 'SETUP_DASHBOARD_ADDRESSES',
    SETUP_DASHBOARD_ADDRESSES_SUCCESS: 'SETUP_DASHBOARD_ADDRESSES_SUCCESS',
    SETUP_DASHBOARD_ADDRESSES_FAILURE: 'SETUP_DASHBOARD_ADDRESSES_FAILURE',

    SETUP_DASHBOARD_JOBS: 'SETUP_DASHBOARD_JOBS',
    SETUP_DASHBOARD_JOBS_SUCCESS: 'SETUP_DASHBOARD_JOBS_SUCCESS',
    SETUP_DASHBOARD_JOBS_FAILURE: 'SETUP_DASHBOARD_JOBS_FAILURE',

    SETUP_DASHBOARD_CREDITS: 'SETUP_DASHBOARD_CREDITS',
    SETUP_DASHBOARD_CREDITS_SUCCESS: 'SETUP_DASHBOARD_CREDITS_SUCCESS',
    SETUP_DASHBOARD_CREDITS_FAILURE: 'SETUP_DASHBOARD_CREDITS_FAILURE',
    
    SET_QUOTE_BRAND_DATA: 'SET_QUOTE_BRAND_DATA',
    SET_QUOTE_BRAND_DATA_SUCCESS: 'SET_QUOTE_BRAND_DATA_SUCCESS',
    SET_QUOTE_BRAND_DATA_FAILURE: 'SET_QUOTE_BRAND_DATA_FAILURE',

    SET_ORDER_BRAND_DATA: 'SET_ORDER_BRAND_DATA',
    SET_ORDER_BRAND_DATA_SUCCESS: 'SET_ORDER_BRAND_DATA_SUCCESS',
    SET_ORDER_BRAND_DATA_FAILURE: 'SET_ORDER_BRAND_DATA_FAILURE',

    DASHBOARD_GET_QUOTE_BY_ID: 'DASHBOARD_GET_QUOTE_BY_ID',
    DASHBOARD_GET_QUOTE_BY_ID_SUCCESS: 'DASHBOARD_GET_QUOTE_BY_ID_SUCCESS',
    DASHBOARD_GET_QUOTE_BY_ID_FAILURE: 'DASHBOARD_GET_QUOTE_BY_ID_FAILURE',

    DASHBOARD_GET_ORDER_BY_ID: 'DASHBOARD_GET_ORDER_BY_ID',
    DASHBOARD_GET_ORDER_BY_ID_SUCCESS: 'DASHBOARD_GET_ORDER_BY_ID_SUCCESS',
    DASHBOARD_GET_ORDER_BY_ID_FAILURE: 'DASHBOARD_GET_ORDER_BY_ID_FAILURE',

    UPDATE_CUSTOMER_COMM_LOG: 'UPDATE_CUSTOMER_COMM_LOG',
    UPDATE_CUSTOMER_COMM_LOG_SUCCESS: 'UPDATE_CUSTOMER_COMM_LOG_SUCCESS',
    UPDATE_CUSTOMER_COMM_LOG_FAILURE: 'UPDATE_CUSTOMER_COMM_LOG_FAILURE',

    GET_LIST_OF_PREFERENCES: 'GET_LIST_OF_PREFERENCES',
    GET_LIST_OF_PREFERENCES_SUCCESS: 'GET_LIST_OF_PREFERENCES_SUCCESS',
    GET_LIST_OF_PREFERENCES_FAILURE: 'GET_LIST_OF_PREFERENCES_FAILURE',

    // Quote/ID
    FETCH_LOST_QUOTE_REASONS: 'FETCH_LOST_QUOTE_REASONS',
    FETCH_LOST_QUOTE_REASONS_SUCCESS: 'FETCH_LOST_QUOTE_REASONS_SUCCESS',
    FETCH_LOST_QUOTE_REASONS_FAILURE: 'FETCH_LOST_QUOTE_REASONS_FAILURE',

    GET_SINGLE_QUOTE_DETAILS: 'GET_SINGLE_QUOTE_DETAILS',
    GET_SINGLE_QUOTE_DETAILS_SUCCESS: 'GET_SINGLE_QUOTE_DETAILS_SUCCESS',
    GET_SINGLE_QUOTE_DETAILS_FAILURE: 'GET_SINGLE_QUOTE_DETAILS_FAILURE',

    GET_SINGLE_QUOTE_SECTION_DETAILS: 'GET_SINGLE_QUOTE_SECTION_DETAILS',
    GET_SINGLE_QUOTE_SECTION_DETAILS_SUCCESS: 'GET_SINGLE_QUOTE_SECTION_DETAILS_SUCCESS',
    GET_SINGLE_QUOTE_SECTION_DETAILS_FAILURE: 'GET_SINGLE_QUOTE_SECTION_DETAILS_FAILURE',

    GET_SINGLE_QUOTE_SECTION_ITEMS: 'GET_SINGLE_QUOTE_SECTION_ITEMS',
    GET_SINGLE_QUOTE_SECTION_ITEMS_SUCCESS: 'GET_SINGLE_QUOTE_SECTION_ITEMS_SUCCESS',
    GET_SINGLE_QUOTE_SECTION_ITEMS_FAILURE: 'GET_SINGLE_QUOTE_SECTION_ITEMS_FAILURE',

    GET_SINGLE_QUOTE_SECTION_CHARGES: 'GET_SINGLE_QUOTE_SECTION_CHARGES',
    GET_SINGLE_QUOTE_SECTION_CHARGES_SUCCESS: 'GET_SINGLE_QUOTE_SECTION_CHARGES_SUCCESS',
    GET_SINGLE_QUOTE_SECTION_CHARGES_FAILURE: 'GET_SINGLE_QUOTE_SECTION_CHARGES_FAILURE',

    GET_SINGLE_QUOTE_SECTION_MANAGEMENT: 'GET_SINGLE_QUOTE_SECTION_MANAGEMENT',
    GET_SINGLE_QUOTE_SECTION_MANAGEMENT_SUCCESS: 'GET_SINGLE_QUOTE_SECTION_MANAGEMENT_SUCCESS',
    GET_SINGLE_QUOTE_SECTION_MANAGEMENT_FAILURE: 'GET_SINGLE_QUOTE_SECTION_MANAGEMENT_FAILURE',

    UPDATE_QUOTE_DETAILS: 'UPDATE_QUOTE_DETAILS',
    UPDATE_QUOTE_DETAILS_SUCCESS: 'UPDATE_QUOTE_DETAILS_SUCCESS',
    UPDATE_QUOTE_DETAILS_FAILURE: 'UPDATE_QUOTE_DETAILS_FAILURE',

    UPDATE_QUOTE_CARRIAGE: 'UPDATE_QUOTE_CARRIAGE',
    UPDATE_QUOTE_CARRIAGE_SUCCESS: 'UPDATE_QUOTE_CARRIAGE_SUCCESS',
    UPDATE_QUOTE_CARRIAGE_FAILURE: 'UPDATE_QUOTE_CARRIAGE_FAILURE',

    // Quote/ID

    // Order/ID
    GET_SINGLE_ORDER_DETAILS: 'GET_SINGLE_ORDER_DETAILS',
    GET_SINGLE_ORDER_DETAILS_SUCCESS: 'GET_SINGLE_ORDER_DETAILS_SUCCESS',
    GET_SINGLE_ORDER_DETAILS_FAILURE: 'GET_SINGLE_ORDER_DETAILS_FAILURE',

    GET_SINGLE_ORDER_SECTION_DETAILS: 'GET_SINGLE_ORDER_SECTION_DETAILS',
    GET_SINGLE_ORDER_SECTION_DETAILS_SUCCESS: 'GET_SINGLE_ORDER_SECTION_DETAILS_SUCCESS',
    GET_SINGLE_ORDER_SECTION_DETAILS_FAILURE: 'GET_SINGLE_ORDER_SECTION_DETAILS_FAILURE',

    GET_SINGLE_ORDER_SECTION_ITEMS: 'GET_SINGLE_ORDER_SECTION_ITEMS',
    GET_SINGLE_ORDER_SECTION_ITEMS_SUCCESS: 'GET_SINGLE_ORDER_SECTION_ITEMS_SUCCESS',
    GET_SINGLE_ORDER_SECTION_ITEMS_FAILURE: 'GET_SINGLE_ORDER_SECTION_ITEMS_FAILURE',

    GET_SINGLE_ORDER_SECTION_CHARGES: 'GET_SINGLE_ORDER_SECTION_CHARGES',
    GET_SINGLE_ORDER_SECTION_CHARGES_SUCCESS: 'GET_SINGLE_ORDER_SECTION_CHARGES_SUCCESS',
    GET_SINGLE_ORDER_SECTION_CHARGES_FAILURE: 'GET_SINGLE_ORDER_SECTION_CHARGES_FAILURE',

    GET_SINGLE_ORDER_SECTION_MANAGEMENT: 'GET_SINGLE_ORDER_SECTION_MANAGEMENT',
    GET_SINGLE_ORDER_SECTION_MANAGEMENT_SUCCESS: 'GET_SINGLE_ORDER_SECTION_MANAGEMENT_SUCCESS',
    GET_SINGLE_ORDER_SECTION_MANAGEMENT_FAILURE: 'GET_SINGLE_ORDER_SECTION_MANAGEMENT_FAILURE',

    ORDER_GET_EMAIL_LOG: 'ORDER_GET_EMAIL_LOG',
    ORDER_GET_EMAIL_LOG_SUCCESS: 'ORDER_GET_EMAIL_LOG_SUCCESS',
    ORDER_GET_EMAIL_LOG_FAILURE: 'ORDER_GET_EMAIL_LOG_FAILURE',
    

    // Order/ID

    DASHBOARD_UPDATE_QUOTE: 'DASHBOARD_UPDATE_QUOTE',
    DASHBOARD_UPDATE_QUOTE_SUCCESS: 'DASHBOARD_UPDATE_QUOTE_SUCCESS',
    DASHBOARD_UPDATE_QUOTE_FAILURE: 'DASHBOARD_UPDATE_QUOTE_FAILURE',

    DASHBOARD_GET_VEHICLE_SIZES: 'DASHBOARD_GET_VEHICLE_SIZES',
    DASHBOARD_GET_VEHICLE_SIZES_SUCCESS: 'DASHBOARD_GET_VEHICLE_SIZES_SUCCESS',
    DASHBOARD_GET_VEHICLE_SIZES_FAILURE: 'DASHBOARD_GET_VEHICLE_SIZES_FAILURE',

    DASHBOARD_RESET_ACTIVE_QUOTE_STATE: 'DASHBOARD_RESET_ACTIVE_QUOTE_STATE',
    DASHBOARD_RESET_ACTIVE_ORDER_STATE: 'DASHBOARD_RESET_ACTIVE_ORDER_STATE',

    CONVERT_QUOTE: 'CONVERT_QUOTE',
    CONVERT_QUOTE_SUCCESS: 'CONVERT_QUOTE_SUCCESS',
    CONVERT_QUOTE_FAILURE: 'CONVERT_QUOTE_FAILURE',

    DASHBOARD_GET_AUDIT_TRAIL: 'DASHBOARD_GET_AUDIT_TRAIL',
    DASHBOARD_GET_AUDIT_TRAIL_SUCCESS: 'DASHBOARD_GET_AUDIT_TRAIL_SUCCESS',
    DASHBOARD_GET_AUDIT_TRAIL_FAILURE: 'DASHBOARD_GET_AUDIT_TRAIL_FAILURE',

    DASHBOARD_BUILD_SNAPSHOTS: 'DASHBOARD_BUILD_SNAPSHOTS',
    DASHBOARD_BUILD_SNAPSHOTS_SUCCESS: 'DASHBOARD_BUILD_SNAPSHOTS_SUCCESS',
    DASHBOARD_BUILD_SNAPSHOTS_FAILURE: 'DASHBOARD_BUILD_SNAPSHOTS_FAILURE',

    DASHBOARD_QUOTE_HANDLE_PAYMENT: 'DASHBOARD_QUOTE_HANDLE_PAYMENT',
    DASHBOARD_QUOTE_HANDLE_PAYMENT_SUCCESS: 'DASHBOARD_QUOTE_HANDLE_PAYMENT_SUCCESS',
    DASHBOARD_QUOTE_HANDLE_PAYMENT_FAILURE: 'DASHBOARD_QUOTE_HANDLE_PAYMENT_FAILURE',

    DASHBOARD_ORDER_HANDLE_PAYMENT: 'DASHBOARD_ORDER_HANDLE_PAYMENT',
    DASHBOARD_ORDER_HANDLE_PAYMENT_SUCCESS: 'DASHBOARD_ORDER_HANDLE_PAYMENT_SUCCESS',
    DASHBOARD_ORDER_HANDLE_PAYMENT_FAILURE: 'DASHBOARD_ORDER_HANDLE_PAYMENT_FAILURE',

    DASHBOARD_QUOTE_HANDLE_BACS: 'DASHBOARD_QUOTE_HANDLE_BACS',
    DASHBOARD_QUOTE_HANDLE_BACS_SUCCESS: 'DASHBOARD_QUOTE_HANDLE_BACS_SUCCESS',
    DASHBOARD_QUOTE_HANDLE_BACS_FAILURE: 'DASHBOARD_QUOTE_HANDLE_BACS_FAILURE',

    CREATE_NEW_QUOTE: 'CREATE_NEW_QUOTE',
    CREATE_NEW_QUOTE_SUCCESS: 'CREATE_NEW_QUOTE_SUCCESS',
    CREATE_NEW_QUOTE_FAILURE: 'CREATE_NEW_QUOTE_FAILURE',

    CREATE_BLANK_CUSTOMER_QUOTE: 'CREATE_BLANK_CUSTOMER_QUOTE',
    CREATE_BLANK_CUSTOMER_QUOTE_SUCCESS: 'CREATE_BLANK_CUSTOMER_QUOTE_SUCCESS',
    CREATE_BLANK_CUSTOMER_QUOTE_FAILURE: 'CREATE_BLANK_CUSTOMER_QUOTE_FAILURE',

    RESET_QUOTE_STATE: 'RESET_QUOTE_STATE',
    
    QUOTE_ORDER_SEND_EMAIL: 'QUOTE_ORDER_SEND_EMAIL',
    QUOTE_ORDER_SEND_EMAIL_SUCCESS: 'QUOTE_ORDER_SEND_EMAIL_SUCCESS',
    QUOTE_ORDER_SEND_EMAIL_FAILURE: 'QUOTE_ORDER_SEND_EMAIL_FAILURE',

    QUOTE_GET_EMAIL_LOG: 'QUOTE_GET_EMAIL_LOG',
    QUOTE_GET_EMAIL_LOG_SUCCESS: 'QUOTE_GET_EMAIL_LOG_SUCCESS',
    QUOTE_GET_EMAIL_LOG_FAILURE: 'QUOTE_GET_EMAIL_LOG_FAILURE',


    // DASH/QUOTES
    GET_CUSTOMER_QUOTES: 'GET_CUSTOMER_QUOTES',
    GET_CUSTOMER_QUOTES_SUCCESS: 'GET_CUSTOMER_QUOTES_SUCCESS',
    GET_CUSTOMER_QUOTES_FAILURE: 'GET_CUSTOMER_QUOTES_FAILURE',

    // DASH/ORDERS
    GET_CUSTOMER_ORDERS: 'GET_CUSTOMER_ORDERS',
    GET_CUSTOMER_ORDERS_SUCCESS: 'GET_CUSTOMER_ORDERS_SUCCESS',
    GET_CUSTOMER_ORDERS_FAILURE: 'GET_CUSTOMER_ORDERS_FAILURE',

    //DASH/ORDERS/ID
    GET_SINGLE_ORDER_BY_ID: 'GET_SINGLE_ORDER_BY_ID',
    GET_SINGLE_ORDER_BY_ID_SUCCESS: 'GET_SINGLE_ORDER_BY_ID_SUCCESS',
    GET_SINGLE_ORDER_BY_ID_FAILURE: 'GET_SINGLE_ORDER_BY_ID_FAILURE',

    // DASH/ADDRESSES
    GET_ADDRESSES: 'GET_ADDRESSES',
    GET_ADDRESSES_SUCCESS: 'GET_ADDRESSES_SUCCESS',
    GET_ADDRESSES_FAILURE: 'GET_ADDRESSES_FAILURE',

    EDIT_ADDRESS: 'EDIT_ADDRESS',
    EDIT_ADDRESS_SUCCESS: 'EDIT_ADDRESS_SUCCESS',
    EDIT_ADDRESS_FAILURE: 'EDIT_ADDRESS_FAILURE',

    NEW_ADDRESS: 'NEW_ADDRESS',
    NEW_ADDRESS_SUCCESS: 'NEW_ADDRESS_SUCCESS',
    NEW_ADDRESS_FAILURE: 'NEW_ADDRESS_FAILURE',

    COPY_ADDRESS: 'COPY_ADDRESS',
    COPY_ADDRESS_SUCCESS: 'COPY_ADDRESS_SUCCESS',
    COPY_ADDRESS_FAILURE: 'COPY_ADDRESS_FAILURE',

    // CRM/Schedule

    SCHEDULE_GET_BY_POSTCODE_DELIVERIES: 'SCHEDULE_GET_BY_POSTCODE_DELIVERIES',
    SCHEDULE_GET_BY_POSTCODE_DELIVERIES_SUCCESS: 'SCHEDULE_GET_BY_POSTCODE_DELIVERIES_SUCCESS',
    SCHEDULE_GET_BY_POSTCODE_DELIVERIES_FAILURE: 'SCHEDULE_GET_BY_POSTCODE_DELIVERIES_FAILURE',

    SCHEDULE_GET_BY_POSTCODE_COLLECTIONS: 'SCHEDULE_GET_BY_POSTCODE_COLLECTIONS',
    SCHEDULE_GET_BY_POSTCODE_COLLECTIONS_SUCCESS: 'SCHEDULE_GET_BY_POSTCODE_COLLECTIONS_SUCCESS',
    SCHEDULE_GET_BY_POSTCODE_COLLECTIONS_FAILURE: 'SCHEDULE_GET_BY_POSTCODE_COLLECTIONS_FAILURE',


    //CRM/SETTINGS
    GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES: 'GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES',
    GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES_SUCCESS: 'GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES_SUCCESS',
    GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES_FAILURE: 'GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES_FAILURE',

    GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES: 'GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES',
    GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES_SUCCESS: 'GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES_SUCCESS',
    GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES_FAILURE: 'GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES_FAILURE',
    
    //singles
    RESET_ORDER: 'RESET_ORDER',
    QUOTE_RESET_QUOTE_PAYMENT: "QUOTE_RESET_QUOTE_PAYMENT",
    QUOTE_UPDATE_STATUS_COLOUR: "QUOTE_UPDATE_STATUS_COLOUR",
    QUOTE_UPDATE_PAYMENT_STATUS: "QUOTE_UPDATE_PAYMENT_STATUS",
    ORDER_UPDATE_PAYMENT_STATUS: "ORDER_UPDATE_PAYMENT_STATUS",
    QUOTE_CREATE_CARRIAGE: "QUOTE_CREATE_CARRIAGE",
    QUOTE_ORDER_RESET_SENT_EMAIL: "QUOTE_ORDER_RESET_SENT_EMAIL",
    DASHBOARD_UPDATE_PRODUCT_LIMITATIONS: "DASHBOARD_UPDATE_PRODUCT_LIMITATIONS",
    QUOTE_SET_SUCCESSFUL_QUOTE_UPDATE: "QUOTE_SET_SUCCESSFUL_QUOTE_UPDATE",
    QUOTE_UPDATE_CHARGES: "QUOTE_UPDATE_CHARGES",
    QUOTE_BULK_UPDATE_STATES: "QUOTE_BULK_UPDATE_STATES",
    QUOTE_SET_STATE: "QUOTE_SET_STATE",
    QUOTE_RESET_STATES: "QUOTE_RESET_STATES",
    ORDER_SET_STATE: "ORDER_SET_STATE",
    DASHBOARD_SET_NEW_QUOTE: 'DASHBOARD_SET_NEW_QUOTE',
    DASHBOARD_SET_CURR_QUOTE: 'DASHBOARD_SET_CURR_QUOTE',
    DASHBOARD_SET_NEW_ORDER: 'DASHBOARD_SET_NEW_ORDER',
    DASHBOARD_SET_CURR_ORDER: 'DASHBOARD_SET_CURR_ORDER',
    TOGGLE_CHECKLIST_SAME_AS_DELIVERY: "TOGGLE_CHECKLIST_SAME_AS_DELIVERY",
    SET_CHECKLIST_COMPLETED: "SET_CHECKLIST_COMPLETED",
    HANDLE_NAVIGATE_AWAY_QUOTE_STATE: 'HANDLE_NAVIGATE_AWAY_QUOTE_STATE',
    DASHBOARD_SET_LIMITATIONS: 'DASHBOARD_SET_LIMITATIONS',
    DASHBOARD_SET_NEW_ORDER_ITEMS: 'DASHBOARD_SET_NEW_ORDER_ITEMS',
    SET_CONVERT_QUOTE_STATE: "SET_CONVERT_QUOTE_STATE",
    DASHBOARD_RESET_UPDATED_QUOTE: "DASHBOARD_RESET_UPDATED_QUOTE",
    TOGGLE_MOBILE_SIDE_NAV: "TOGGLE_MOBILE_SIDE_NAV",
    UPDATE_ADDRESSES: "UPDATE_ADDRESSES",
    SET_SINGLE_CREDIT_DATA: "SET_SINGLE_CREDIT_DATA"
  }
  
  export default actions;
  