import {all, call, put, takeLatest} from 'redux-saga/effects'
import actions from './actions';
import {postRequest, getCustomRequest, getRequest, getBaseRequest, getPrivateRequest, deleteRequest} from '../../config/axiosClient'

function* getTracking(action) {
  try {
    const tracking_ref = action.payload;
    const response = yield call(() => getRequest('tracking/check_carriage_id?tracking_ref=' + tracking_ref)); 
    const status = response.data == true ? false : 'Invalid tracking Ref'
    
    const data = {
      statusMessage: status,
      redirect: response.data,
    }

    yield put({type: actions.TRACKING_GET_SUCCESS, payload: data})
  } catch (error) {
    yield put({type: actions.TRACKING_GET_FAILURE, payload: error})
  }
}

function* setTracking(action) {
  try {
    const tracking_ref = action.payload;

    const carriage = yield call(() => getBaseRequest('get-carriage-id?tracking_ref=' + tracking_ref)); 
    const carriage_data = {
      carriage_id: carriage.data.carriage_id,
      carriage_note: carriage.data.carriage_note,
      tracking_history: carriage.data.tracking_history
    }
  
    yield put({type: actions.TRACKING_SET_SUCCESS, payload: carriage_data})
  } catch (error) {
    yield put({type: actions.TRACKING_SET_FAILURE, payload: error})
  }
}



export default function* rootSaga() {
  yield all([takeLatest(actions.TRACKING_GET, getTracking)]);
  yield all([takeLatest(actions.TRACKING_SET, setTracking)]);
}
