const actions = {

    GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
    GET_COUNTRY_LIST_SUCCESS: 'GET_COUNTRY_LIST_SUCCESS',
    GET_COUNTRY_LIST_FAILURE: 'GET_COUNTRY_LIST_FAILURE',
    
    GET_SECTORS: 'GET_SECTORS',
    GET_SECTORS_SUCCESS: 'GET_SECTORS_SUCCESS',
    GET_SECTORS_FAILURE: 'GET_SECTORS_FAILURE',

    AGENT_LOGIN_AS_USER: 'AGENT_LOGIN_AS_USER',
    AGENT_LOGIN_AS_USER_SUCCESS: 'AGENT_LOGIN_AS_USER_SUCCESS',
    AGENT_LOGIN_AS_USER_FAILURE: 'AGENT_LOGIN_AS_USER_FAILURE',

    AGENT_ACT_AS_CUSTOMER_ID: 'AGENT_ACT_AS_CUSTOMER_ID',
    AGENT_ACT_AS_CUSTOMER_ID_SUCCESS: 'AGENT_ACT_AS_CUSTOMER_ID_SUCCESS',
    AGENT_ACT_AS_CUSTOMER_ID_FAILURE: 'AGENT_ACT_AS_CUSTOMER_ID_FAILURE',

    AGENT_UPDATE_QUOTE_DATA: 'AGENT_UPDATE_QUOTE_DATA',
    AGENT_UPDATE_QUOTE_DATA_SUCCESS: 'AGENT_UPDATE_QUOTE_DATA_SUCCESS',
    AGENT_UPDATE_QUOTE_DATA_FAILURE: 'AGENT_UPDATE_QUOTE_DATA_FAILURE',
    
    GET_SCHEDULED_CARRIAGES: 'GET_SCHEDULED_CARRIAGES',
    GET_SCHEDULED_CARRIAGES_SUCCESS: 'GET_SCHEDULED_CARRIAGES_SUCCESS',
    GET_SCHEDULED_CARRIAGES_FAILURE: 'GET_SCHEDULED_CARRIAGES_FAILURE',

    GET_SCHEDULE_BY_WEEK: 'GET_SCHEDULE_BY_WEEK',
    GET_SCHEDULE_BY_WEEK_SUCCESS: 'GET_SCHEDULE_BY_WEEK_SUCCESS',
    GET_SCHEDULE_BY_WEEK_FAILURE: 'GET_SCHEDULE_BY_WEEK_FAILURE',
    
    GET_SINGLE_CARRIAGE: 'GET_SINGLE_CARRIAGE',
    GET_SINGLE_CARRIAGE_SUCCESS: 'GET_SINGLE_CARRIAGE_SUCCESS',
    GET_SINGLE_CARRIAGE_FAILURE: 'GET_SINGLE_CARRIAGE_FAILURE',
    
    GET_QUOTES_AND_ORDERS_FOR_MAP: 'GET_QUOTES_AND_ORDERS_FOR_MAP',
    GET_QUOTES_AND_ORDERS_FOR_MAP_SUCCESS: 'GET_QUOTES_AND_ORDERS_FOR_MAP_SUCCESS',
    GET_QUOTES_AND_ORDERS_FOR_MAP_FAILURE: 'GET_QUOTES_AND_ORDERS_FOR_MAP_FAILURE',

    RESET_UPDATED_QUOTE: 'RESET_UPDATED_QUOTE',
    UPDATE_CONFIRMATION_CALL: 'UPDATE_CONFIRMATION_CALL',


    //DASHBOARD (AGENT)
    SET_USER_STATE: "SET_USER_STATE",
    SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
    SET_ACTIVE_CUSTOMER_IN_LIST: 'SET_ACTIVE_CUSTOMER_IN_LIST',
    RESET_SINGLE_CARRIAGE: "RESET_SINGLE_CARRIAGE",

  }
  
  export default actions;
  