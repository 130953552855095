import actions from './actions';
import { isDefined } from '../../config/helpers';
import CacheFactory from '../../Factories/CacheFactory';

const initialState = {
  TopCart: {
    open: false,
    data: null,
    title: null,
    type: null,
    alternativeProps: [],
  },
  TopCartModal: {
    open: false,
    type: null,
    title: '',
  },
  RightDrawer: {
    open: false,
    title: null,
  },
  CreateAccount: {
    open: false,
  }
};

function Reducer(state = initialState, action) {
  switch (action.type) {

    case actions.HANDLE_TOP_CART_MODAL_TOGGLE:
      return {
          ...state,
          TopCartModal: action.payload
    };

    case actions.TOP_MODAL_OPEN:
      return {
          ...state,
          TopCart: action.payload
    };
    case actions.TOP_MODAL_CLOSE:
        return {
            ...state,
            TopCart: initialState.TopCart
    };
    case actions.MODAL_SET_PRODUCT_PRICE:
        return {
            ...state,
            TopCart: {
                ...state.TopCart,
                data: {
                  ...state.TopCart.data,
                  array: [
                    ...state.TopCart.data.array.slice(0, action.payload.index), //remove the old index -> update it -> reinsert it
                    {
                      ...state.TopCart.data.array[action.payload.index],
                        data: action.payload.product,
                    },
                    ...state.TopCart.data.array.slice(action.payload.index+1)
                  ]
                },
            },
    };
    case actions.MODAL_SET_ALTERNATIVE_PROGRESS_PERCENTAGE:
        return {
            ...state,
            TopCart: {
                ...state.TopCart,
                alternativeProps: {
                  ...state.TopCart.alternativeProps,
                  percentage: action.payload,
                }
            },
    };
    case actions.MODAL_SET_ALTERNATIVE_COUNT:
        return {
            ...state,
            TopCart: {
                ...state.TopCart,
                alternativeProps: {
                  ...state.TopCart.alternativeProps,
                  alternativeCount: state.TopCart.alternativeProps.alternativeCount +1,
                }
            },
    };
    case actions.MODAL_SET_ALTERNATIVE_CHECK_COMPLETED:
        return {
          ...state,
          TopCart: {
              ...state.TopCart,
              alternativeProps: {
                ...state.TopCart.alternativeProps,
                checkingStock: false,
              }
          },
    };
    case actions.MODAL_SET_PRODUCT_AVAILABILITY:
        return {
            ...state,
            TopCart: {
                ...state.TopCart,
                data: {
                  ...state.TopCart.data,
                  array: [
                    ...state.TopCart.data.array.slice(0, action.payload.index),
                    {
                      ...state.TopCart.data.array[action.payload.index],
                        available: action.payload.availability,
                        limitation_message: action.payload.limitation_message,
                    },
                    ...state.TopCart.data.array.slice(action.payload.index+1)
                  ]
                }
            },
    };
    
    case actions.MODAL_SET_AVAILABLE_ALTERNATIVES:
        return {
            ...state,
            TopCart: {
                ...state.TopCart,
                alternativeProps: {
                  ...state.TopCart.alternativeProps,
                  hasAvailableAlternatives: action.payload,
                }
            },
    };


    //APPBAR

    
    case actions.RIGHT_DRAWER_MODAL_OPEN:
      return {
          ...state,
          RightDrawer: action.payload
    };
    case actions.RIGHT_DRAWER_MODAL_CLOSE:
        return {
            ...state,
            RightDrawer: { //initial props of the topcart
                ...state.RightDrawer,
                open: false,
                title: null,
            },
    };

    case actions.CREATE_ACCOUNT_MODAL_TOGGLE:
      return {
          ...state,
          CreateAccount: {
            open: action.payload
          }
    };

    default:
      return state
  }
}

export default Reducer;
