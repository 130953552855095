import {all, call, put, takeLatest, select} from 'redux-saga/effects'
import actions from './actions';
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, getBaseRequest} from '../../config/axiosClient'
import { useSelector } from 'react-redux';
import { isDefined, isNull } from '../../config/helpers';
import CacheFactory from '../../Factories/CacheFactory';

//reducer exports
export const getQuoteReducer = (state) => state.quoteReducer;
export const getCartReducer = (state) => state.cartReducer;
export const getModal = (state) => state.modalsReducer;
// function* cartSetAlternatives(action) {
//   try {
//     yield put({type: actions.CART_SET_ALTERNATIVES_SUCCESS, payload: action.payload});
//   } catch (error) {
//     yield put({type: actions.CART_SET_ALTERNATIVES_FAILURE, payload: error});
//   }

// }

function* modalCheckStock(action) {
  //grab the current state of the quote
    try {
    const reducer = yield select(getQuoteReducer); 
    const quote = reducer.quote;

    //grab the current index specified in payload
    let index = action.payload.data.index;

    //grab the current index specified to that type e.g padIndex
    const typeIndex = action.payload.data.typeIndex;

    //bows/product/pad etc
    const alternativeType = action.payload.data.alternativeType;

    //grab alternatives
    const alternatives = action.payload.data.array;

    //create a copy of the topcart 
    const topCartCopy = action.payload;

    //specify the qty that we are looking to swap/replace
    let qty = topCartCopy.data.qty;

    let toMatch;
    let object;
    switch (alternativeType) {
      case 'pads':
        toMatch = quote.cart[index][alternativeType][typeIndex];
        object = quote.cart[index][alternativeType];
        break;
    
      default:
        toMatch = quote.cart[index]
        object = quote.cart
        break;
    }

    const updatedAlternatives = alternatives.map((alternative, key) => { //map through the current alternatives
      const copy = {...alternative}; //create copies of cart and the item

      if(alternative.data.product_code == toMatch.data.product_code){ //if the alternative product code matches, add up the qtys
          copy.qty = parseInt(qty) + parseInt(toMatch.qty)
      }
      else {
          copy.qty = parseInt(qty)
      }

        return copy; //return new item
    });

    yield call(checkStockLoop, quote, updatedAlternatives, 0, alternativeType, object) //run function checkStockLoop on this index
  } catch (error) {
    // console.log(error)
  }

}

function* checkStockLoop(quote, alternatives, index, alternativeType, object) {
  try {
    
    const modal = yield select(getModal);
    const cartReducer = yield select(getCartReducer);
    const cart = cartReducer.cart;
    const type = alternativeType == 'cart' ? 'content' : alternativeType;
    const alternativeCount = modal.TopCart.alternativeProps.alternativeCount; //tracker to see how many successful alternatives are returned
    let available;

    /**
     * Conditions to see whether we need to complete the stock check loop
     * - Have 3 successful alternatives been logged
     * - Are we at the last iteration of the cart
     * - Does the loop have at least 1 alternative
     */
    if((alternativeCount == 3) || (index == alternatives.length && alternativeCount > 0)){
      yield put({type: actions.MODAL_SET_ALTERNATIVE_CHECK_COMPLETED});
    }


    if(index == alternatives.length) { //if all alternatives have been checked, end the loop.
      return;
    }

    //TODO: check if product is itself, if it is, dont run a stock check

    alternatives[index].data = {
      ...alternatives[index].data,
      qty: alternatives[index].qty
    }


    const updated = object.forEach(function(item, itemIndex) { //loop through every product in the cat
      if(item.data.product_code == alternatives[index].data.product_code){  //if the product alternative is the same as the product in the iteration
        alternatives[index].data = {
          ...alternatives[index].data,
          qty: parseInt(alternatives[index].qty) + parseInt(item.qty) //add the two qtys together
        }
        alternatives[index] = {
          ...alternatives[index],
          qty: parseInt(alternatives[index].qty) + parseInt(item.qty)
        }
      }
    })


    const api_data = { //object to set the data for the current stock check iteration
      postcode: quote.deliveryPostcode,
      totalExVat: quote.totalExVat,
      hireDays: quote.hireDays,
      deliveryDateTimestamp: quote.deliveryDateTimestamp,
      collectionDateTimestamp: quote.collectionDateTimestamp,
      item: alternatives[index],
      index: index,
      webquote: quote
    }

    const response = yield call(() => postRequest('quote/check-stock', api_data))



    //if there are no limitations, make an api request to check for product price breaks
    if(response.data.limitations != true){
      const data = {
        product: alternatives[index].data,
        brand: quote.brand,
        index: index,
      };
      const priceBreaksCheck = yield call(() => postRequest('product/price-breaks', data))

      //based on the data from the api request, update the product pricing
      const updatedAlternativePricing = {
        product: priceBreaksCheck.data.product,
        index: index,
      }

      available = true; //set the availability of that product to true
      yield put({type: actions.MODAL_SET_PRODUCT_PRICE, payload: updatedAlternativePricing});
      yield put({type: actions.MODAL_SET_ALTERNATIVE_COUNT});

      if(!modal.TopCart.alternativeProps.hasAvailableAlternatives){
        yield put({type: actions.MODAL_SET_AVAILABLE_ALTERNATIVES, payload: available});
      }


    }
    else {
      //if there are any limitations, set the availiablity to false
      available = false;
    }

    yield put({type: actions.MODAL_SET_PRODUCT_AVAILABILITY, payload: {availability: available, limitation_message: 'In Stock', index: index} }); //set the availability of the altnerative checked
      
    index++; //increment the index by 1
    const percentage = Math.floor((index/alternatives.length)*100); //create a percentage based on the index and number of alternatives
    yield put({type: actions.MODAL_SET_ALTERNATIVE_PROGRESS_PERCENTAGE, payload: percentage}); //set this percentage into the current state

    yield call(checkStockLoop, quote, alternatives, index, alternativeType, object) //continue the loop by calling its own function with updated index
  } catch (error) {
    // console.log(error)
  }
  
}

export default function* rootSaga() {
  yield all([takeLatest(actions.TOP_MODAL_CHECK_STOCK, modalCheckStock)]);

}
