import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Payment/actions';
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postBaseRequest} from '../../../config/axiosClient'
import Bugsnag from '@bugsnag/js';



function* getCountries(action) {
  try {
    const response = yield call(() => getRequest('doris/countries')); 
    yield put({type: actions.CHECKOUT_PAYMENT_GET_COUNTRIES_SUCCESS, payload: response.data});
  } catch (error) {
    Bugsnag.notify(error);
    yield put({type: actions.CHECKOUT_PAYMENT_GET_COUNTRIES_FAILURE, payload: error});
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.CHECKOUT_PAYMENT_GET_COUNTRIES, getCountries)]);
}
