import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Dashboard/actions'
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postCustomRequest, getRootReq, postRootReq, getBaseRequest, postBaseRequest} from '../../config/axiosClient'
import axios from 'axios';

import {topMiddleErrorAlert, topMiddleSuccessAlert} from '../Alert/apiSaga';

export const generic_error_message = 'Oops, something went wrong!';
export const getDash = (state) => state.dashboardReducer;
export const getAuth = (state) => state.authReducer;


function* getSingleQuoteSectionDetails(action) {
    const {
    quote_id,
    } = action.payload

    const queryString = `dashboard/quote/get-quote-details?quote_id=${quote_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_QUOTE_SECTION_DETAILS_SUCCESS, payload: response.data})
  
    } catch (error) {
        console.log(error)
    //   yield put({type: actions.GET_SINGLE_QUOTE_SECTION_DETAILS_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* getSingleQuoteSectionItems(action) {
    const {
    quote_id,
    } = action.payload

    const queryString = `dashboard/quote/get-quote-items?quote_id=${quote_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_QUOTE_SECTION_ITEMS_SUCCESS, payload: response.data})
  
    } catch (error) {
    //   yield put({type: actions.GET_SINGLE_QUOTE_SECTION_ITEMS_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  

function* getSingleQuoteSectionCharges(action) {
    const {
    quote_id,
    } = action.payload

    const queryString = `dashboard/quote/get-quote-charges?quote_id=${quote_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_QUOTE_SECTION_CHARGES_SUCCESS, payload: response.data})
  
    } catch (error) {
    //   yield put({type: actions.GET_SINGLE_QUOTE_SECTION_CHARGES_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  

function* getSingleQuoteSectionManagement(action) {
    const {
    quote_id,
    } = action.payload

    const queryString = `dashboard/quote/get-quote-management?quote_id=${quote_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_QUOTE_SECTION_MANAGEMENT_SUCCESS, payload: response.data})
  
    } catch (error) {
    //   yield put({type: actions.GET_SINGLE_QUOTE_SECTION_MANAGEMENT_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  

function* fetchLostQuoteReasons(action) {
    const queryString = `agent/get-lost-quote-reasons`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 
      yield put({type: actions.FETCH_LOST_QUOTE_REASONS_SUCCESS, payload: response.data})
  
      if(response.data.status == 'failed'){
        yield put({type: actions.FETCH_LOST_QUOTE_REASONS_FAILURE, payload: response.data})
      }

    } catch (error) {
    //   yield put({type: actions.FETCH_LOST_QUOTE_REASONS_FAILURE, payload: response.data})
      // yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* getEmailLogs(action) {
    const {type, quote_id} = action.payload;
    const queryString = `agent/get-email-logs?type=${type}&quote_id=${quote_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 
      yield put({type: actions.QUOTE_GET_EMAIL_LOG_SUCCESS, payload: response.data})
  
      if(response.data.status == 'failed'){
        yield put({type: actions.QUOTE_GET_EMAIL_LOG_FAILURE, payload: response.data})
      }

    } catch (error) {
    //   yield put({type: actions.FETCH_LOST_QUOTE_REASONS_FAILURE, payload: response.data})
      // yield call(topMiddleErrorAlert, generic_error_message)
    }
}



function* updateQuoteCarriage(action) {


  const dash = yield select(getDash);
  const {updatedQuote: quote, quoteStates} = dash;

  try {

    const postData = {
      customer_id: quote.details.CustomerID,
      freehand_editing: action?.payload?.freehand_editing || false,
      details: {
        QuoteID: quote.details.QuoteID,
        StartDate: quote.details.StartDate,
        EndDate: quote.details.EndDate,
        Extension: Number(quote.details.Extension),
        balance_to_meet_minimum_hire: quote.details.balance_to_meet_minimum_hire,
        damage_waiver_fee: quote.details.damage_waiver_fee,
        non_uk_vat_number: quote.details.non_uk_vat_number,
      },
      quoteStates: {
        deliverySameAsBilling: quoteStates.deliverySameAsBilling,
        collectionSameAsDelivery: quoteStates.collectionSameAsDelivery, 
      },
      order_items: quote.order_items,
      transport: quote.transport,
      charges: quote.charges,
      addresses: quote.addresses,
    }


    const response = yield call(() => postBaseRequest('dashboard/quote/update-quote-carriage', postData) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }


    if(response.data.status == 'success'){
      const data = response.data.data;

      const detailsData = {
        customer_id: quote.details.CustomerID,
        quote_id: quote.details.QuoteID,
        details: quote.details,
        address_ids: data.address_ids,
        CommLog: action.payload.commLog,
      };


      yield put({type: actions.UPDATE_QUOTE_DETAILS, payload: detailsData})

    }
    // else {
    //   yield call(paymentProcessError, response.data.message)
    // }


  } catch (error) {
    console.log(error)
    // yield call(paymentProcessError, generic_error_message)
    yield call(topMiddleErrorAlert, generic_error_message)
    yield put({type: actions.UPDATE_QUOTE_CARRIAGE_FAILURE})
  }
}

function* updateQuoteDetails(action) {

  try {
    const dash = yield select(getDash);
    const {updatedQuote} = dash;
   
      const response = yield call(() => postBaseRequest('dashboard/quote/update-quote-details', {
        ...action.payload,
          section_product_total: updatedQuote.section_product_total
      }) ); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }
      else {
        yield put({type: actions.UPDATE_QUOTE_DETAILS_SUCCESS, payload: response.data})
        yield call(topMiddleSuccessAlert, response.data.message)
      }


  } catch (error) {
    console.log(error)
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}
  

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_SINGLE_QUOTE_SECTION_DETAILS, getSingleQuoteSectionDetails),
    takeLatest(actions.GET_SINGLE_QUOTE_SECTION_ITEMS, getSingleQuoteSectionItems),
    takeLatest(actions.GET_SINGLE_QUOTE_SECTION_CHARGES, getSingleQuoteSectionCharges),
    takeLatest(actions.GET_SINGLE_QUOTE_SECTION_MANAGEMENT, getSingleQuoteSectionManagement),
    takeLatest(actions.QUOTE_GET_EMAIL_LOG, getEmailLogs),
    takeLatest(actions.FETCH_LOST_QUOTE_REASONS, fetchLostQuoteReasons),
    takeLatest(actions.UPDATE_QUOTE_CARRIAGE, updateQuoteCarriage),
    takeLatest(actions.UPDATE_QUOTE_DETAILS, updateQuoteDetails),
  ]);
}
