import actions from '../Tracking/actions';

const initialState = {
  trackingLoaders: [],
  carriage_id: null,
  carriage_note: null,
  tracking_history: null,
  statusMessage: null,
  redirect: false,
}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.TRACKING_GET:
      return {
        ...state,
        trackingLoaders: {
          checkCarriage: true,
        }
    }
    case actions.TRACKING_GET_SUCCESS:
      return {
        ...state,
        trackingLoaders: {
          checkCarriage: false,
        },
        statusMessage: null,
        ...action.payload,
    }
    case actions.TRACKING_GET_FAILURE:
      return {
        ...state,
        trackingLoaders: {
          checkCarriage: false,
        },
    }

    case actions.TRACKING_SET:
      return {
        ...state,
        trackingLoaders: {
          setCarriage: true,
        },
        redirect: false,

    }
    case actions.TRACKING_SET_SUCCESS:
      return {
        ...state,
        trackingLoaders: {
          setCarriage: false,
        },
        ...action.payload,
    }
    case actions.TRACKING_SET_FAILURE:
      return {
        ...state,
        trackingLoaders: {
          setCarriage: false,
        },
    }


    //singles
    case actions.TRACKING_INVALID:
      return {
        ...state,
        trackingLoaders: {
          checkCarriage: false,
        },
        ...action.payload,
    }

    case actions.TRACKING_RESET:
      return {
        ...state,
        trackingLoaders: {
          checkCarriage: false,
        },
        statusMessage: null,
        carriage_id: null,
        carriage_note: null,
        tracking_history: null,
        redirect: false
    }

    default:
      return state
  }
}

export default Reducer;
