const actions = {
    
    INIT_PAYPAL: "INIT_PAYPAL",
    INIT_PAYPAL_SUCCESS: "INIT_PAYPAL_SUCCESS",

    PAYPAL_RESET: "PAYPAL_RESET",

};

export default actions;
