import actions from '../Alert/actions';

const initialState = {
    open: false,
    title: null,
    severity: null,
    variant: null,
    duration: 3000,
    vertical: 'top',
    horizontal: 'center',
}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.ALERT_SET:
      return {
        ...state,
        ...action.payload,
    }
    case actions.ALERT_RESET:
      return {
        ...state,
        open: false,
    }

    default:
      return state
  }
}

export default Reducer;
