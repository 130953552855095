import { produce } from 'immer';
import actions from '../CartManagement/actions';

const initialState = {
	cart: {
		content: [],
		totals: {},
		hireSaving: {},
		totalWeight: 0,
		addonProduct: [
			{
			  productId:null,
			  slug:null,
			  $event:null,
			  source:null
			},
			{
			  productId:null,
			  slug:null,
			  $event:null,
			  source:null
			},
			{
			  productId:null,
			  slug:null,
			  $event:null,
			  source:null
			},
			{
			  productId:null,
			  slug:null,
			  $event:null,
			  source:null
			}
		],
		VATFlag: true,
		counter: 0,
		cartChanged: false
	},
	cartUpdating: false,
	updatingPrices: false,
	retrievingQuote: false,
}

function Reducer(state = initialState, action) {
	switch (action.type) {
		case actions.BUCKET_UPDATE_CART:
			return {
				...state,
				cart: action.payload,
			}
		case actions.BUCKET_UPDATE_CART_COUNTER:
			return {
				...state,
				cart: {
					...state.cart,
					counter: action.payload
				}
			}
		case actions.BUCKET_UPDATE_CART_PRODUCT_ROW:
			return {
				...state,
				// open: false,
			}
		case actions.BUCKET_RESET_CART:
			return {
				...state,
				...initialState
			}

		case actions.BUCKET_SET_CART_LOADER:
			return {
				...state,
				cartUpdating: action.payload,
			}

		case actions.CART_UPDATE_KEY:
			return {
				...state,
				cart: {
					...state.cart,
					[action.payload.key]: action.payload.data
				}
			}
		case actions.BUCKET_SET_UPDATING_PRICES_LOADER:
			return {
				...state,
				updatingPrices: action.payload,
			}

		case actions.SET_CART_RETRIEVE_QUOTE_LOADER:
			return {
				...state,
				retrievingQuote: action.payload,
			}

		case actions.SET_CART_ADDON_PRODUCT:
			return produce(state, (draft) => {
				draft.cart.addonProduct[action.payload.key] = action.payload.data;
			})
		case actions.RESET_ADDON_PRODUCT:
			return {
				...state,
				addonProduct: initialState.addonProduct
			}
		
		case actions.SET_CART_INDEX_DATA:
			return produce(state, (draft) => {
				if(action.payload.padIdx != null){
					draft.cart.content[action.payload.idx] = {
						...draft.cart.content[action.payload.idx],
						...action.payload.data
					}
				}
				else {
					draft.cart.content[action.payload.idx].pads[action.payload.padIdx] = {
						...draft.cart.content[action.payload.idx].pads[action.payload.padIdx],
						...action.payload.data
					}
				}
			})
		

		default:
		return state
	}
}

export default Reducer;
