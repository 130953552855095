import actions from '../Category/actions';

const initialState = {
    categoryState: {
      breadcrumb: [],
      category: [],
      extras: [],
      filters: [],
      layout_type: "",
      long_desc: "",
      long_term: "",
      long_term_hire_discount_rows: [],
      parent: null,
      products: [],
      route: 'category',
      selected_filters: [],
      slides: [],
      son: null,
      sort: "product_order",
      template_page_settings: {},
      total_product_count: 0
    },
    categoryLoaders: {
      fetchingCategory: false
    }
}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CATEGORY:
      return {
        ...state,
        categoryState: action.payload
    }
    case actions.SET_PRODUCTS:
      return {
        ...state, 
        categoryState: {
          ...state.categoryState,
          products: action.payload
        }
    }
    case actions.SET_LOADER:
      return {
        ...state, 
        categoryLoaders: { ...state.categoryLoaders, [action.payload.key]: action.payload.value },
    }
    case actions.RESET_CATEGORIES:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}

export default Reducer;
