import actions from '../Auth/actions';

const initialState = {
    isAuthenticated: false,
    user: false,
    verifyEmail: {},
    forgotPassword: {},
    resetPassword: {},
    linkedAccounts: [],
    defaultAccount: false,
    loginError: "",
    registerError: "",
    authError: "",
    userLoadedError: "",
    setNewDefault: false,
    setNewDefaultMessage: "",
    setNewDefaultError: "",
    defaultAccountError: "",
    failedSetup: false,
    newAccountCreated: false,
    states: {
      verified_email: false,
      verified_user: false,
      setupUser: false,
      checkedDefault: false,
    },
    authLoaders: {
        loginLoading: false,
        initUser: false,
        setNewAccount: false, 
        verifyEmail: false,
    },

}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.AUTH_REGISTER:
      return {
        ...state, authLoaders: { ...state.authLoaders, registerLoading: true },
    }
    case actions.AUTH_REGISTER_SUCCESS:
      return {
        ...state, 
        isAuthenticated: true,
        authLoaders: { ...state.authLoaders, registerLoading: false },
    }
    case actions.AUTH_REGISTER_FAILURE:
      return {
        ...state, 
        isAuthenticated: false,
        registerError: action.payload,
        authLoaders: { ...state.authLoaders, registerLoading: false },
    }

    case actions.AUTH_LOGIN:
      return {
        ...state, authLoaders: { ...state.authLoaders, loginLoading: true },
    }
    case actions.AUTH_LOGIN_SUCCESS:
      return {
        ...state, 
        isAuthenticated: true,
        authLoaders: { ...state.authLoaders, loginLoading: false },
    }
    case actions.AUTH_LOGIN_FAILURE:
      return {
        ...state, 
        isAuthenticated: false,
        user: {},
        loginError: action.payload,
        authLoaders: { ...state.authLoaders, loginLoading: false },
    }

    case actions.AUTH_INIT_USER:
      return {
        ...state, authLoaders: { ...state.authLoaders, initUser: true },
    }
    case actions.AUTH_INIT_USER_SUCCESS:
      return {
        ...state, 
        isAuthenticated: true,
        ...action.payload,
        user: action?.payload?.user ? {
          ...state.user,
          ...action.payload.user
        } : false,
        states: {
          ...state.states,
          verified_user: true,
        },
        authLoaders: { ...state.authLoaders, initUser: false, loginLoading: false },
    }
    case actions.AUTH_INIT_USER_FAILURE:
      return {
        ...state, 
        isAuthenticated: false,
        userLoadedError: action.payload,
        authLoaders: { ...state.authLoaders, initUser: false },
    }

    case actions.AUTH_LOGOUT:
      return {
        ...state, authLoaders: { ...state.authLoaders, logoutUser: true },
    }
    case actions.AUTH_LOGOUT_SUCCESS:
      return {
        ...state, 
        isAuthenticated: false,
        user: false,
        redirect_reason: action?.payload?.reason || 'logout',
        authLoaders: { ...state.authLoaders, logoutUser: false, loginLoading: false, initUser: false },
    }
    case actions.AUTH_LOGOUT_FAILURE:
      return {
        ...state, 
        logoutError: 'Oops! Something went wrong.',
        authLoaders: { ...state.authLoaders, logoutUser: false },
    }

    case actions.AUTH_VERIFY_EMAIL:
      return {
        ...state, authLoaders: { ...state.authLoaders, verifyEmail: true, setupUser: true },
    }
    case actions.AUTH_VERIFY_EMAIL_SUCCESS:
      return {
        ...state, 
        verifyEmail: action.payload,
        states: {
          ...state.states,
          verified_email: true
        },
        authLoaders: { ...state.authLoaders, verifyEmail: false, setupUser: true },
    }
    case actions.AUTH_VERIFY_EMAIL_FAILURE:
      return {
        ...state, 
        verifyEmail: action.payload,
        authLoaders: { ...state.authLoaders, verifyEmail: false },
    }

    case actions.AUTH_FORGOT_PASSWORD:
      return {
        ...state, authLoaders: { ...state.authLoaders, forgotPassword: true },
    }
    case actions.AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state, 
        forgotPassword: action.payload,
        authLoaders: { ...state.authLoaders, forgotPassword: false },
    }
    case actions.AUTH_FORGOT_PASSWORD_FAILURE:
      return {
        ...state, 
        forgotPassword: action.payload,
        authLoaders: { ...state.authLoaders, forgotPassword: false },
    }

    case actions.AUTH_RESET_PASSWORD:
      return {
        ...state, authLoaders: { ...state.authLoaders, resetPassword: true },
    }
    case actions.AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state, 
        resetPassword: action.payload,
        authLoaders: { ...state.authLoaders, resetPassword: false },
    }
    case actions.AUTH_RESET_PASSWORD_FAILURE:
      return {
        ...state, 
        resetPassword: action.payload,
        authLoaders: { ...state.authLoaders, resetPassword: false },
    }

    case actions.AUTH_SEND_EMAIL_VERIFICATION_LINK:
      return {
        ...state, authLoaders: { ...state.authLoaders, sendVerificationLink: true },
    }
    case actions.AUTH_SEND_EMAIL_VERIFICATION_LINK_SUCCESS:
      return {
        ...state, 
        verificationLink: action.payload,
        authLoaders: { ...state.authLoaders, sendVerificationLink: false },
    }
    case actions.AUTH_SEND_EMAIL_VERIFICATION_LINK_FAILURE:
      return {
        ...state, 
        verificationLink: action.payload,
        authLoaders: { ...state.authLoaders, sendVerificationLink: false },
    }

    case actions.AUTH_SETUP_USER:
      return {
        ...state, authLoaders: { ...state.authLoaders, setupUser: true },
    }
    case actions.AUTH_SETUP_USER_SUCCESS:
      return {
        ...state, 
        states: {
          ...state.states,
          setupUser: true
        },
        linkedAccounts: action.payload,
        failedSetup: action.payload.default == null ? true : false,
        authLoaders: { ...state.authLoaders, setupUser: false },
    }
    case actions.AUTH_SETUP_USER_FAILURE:
      return {
        ...state, 
        addressesError: action.payload,
        authLoaders: { ...state.authLoaders, setupUser: false },
    }

    case actions.AUTH_SET_DEFAULT_ACCOUNT:
      return {
        ...state, authLoaders: { ...state.authLoaders, setDefaultAccount: true },
    }
    case actions.AUTH_SET_DEFAULT_ACCOUNT_SUCCESS:
      return {
        ...state, 
        setNewDefault: true,
        setNewDefaultMessage: action.payload.message,
        setNewDefaultError: "",
        authLoaders: { ...state.authLoaders, setDefaultAccount: false },
    }
    case actions.AUTH_SET_DEFAULT_ACCOUNT_FAILURE:
      return {
        ...state, 
        setNewDefault: false,
        setNewDefaultError: action.payload.message,
        authLoaders: { ...state.authLoaders, setDefaultAccount: false },
    }

    case actions.AUTH_GET_USER_ACCOUNT:
      return {
        ...state, authLoaders: { ...state.authLoaders, getDefaultAccount: true },
    }
    case actions.AUTH_GET_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          checkedDefault: true
        },
        defaultAccount: action.payload.account,
        defaultAccountError: "",
        linkedAccounts: {
          ...state.linkedAccounts,
          default: action.payload.account,
          all: action.payload.all
        },
        authLoaders: { ...state.authLoaders, getDefaultAccount: false },
    }
    case actions.AUTH_GET_USER_ACCOUNT_FAILURE:
      return {
        ...state, 
        defaultAccount: [],
        defaultAccountError: action.payload,
        authLoaders: { ...state.authLoaders, getDefaultAccount: false },
    }

    case actions.AUTH_SETUP_ACCOUNT_DETAILS:
      return {
        ...state, 
        authLoaders: { ...state.authLoaders, setNewAccount: true },
    }
    case actions.AUTH_SETUP_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state, 
        setNewDefault: true,
        newAccountCreated: true,
        setNewDefaultMessage: action.payload.message,
        setNewDefaultError: "",
        defaultAccount: action.payload.accounts.default,
        linkedAccounts: {
          ...state.linkedAccounts,
          ...action.payload.accounts,
        },
        authLoaders: { ...state.authLoaders, setNewAccount: false },
    }
    case actions.AUTH_SETUP_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state, 
        setNewDefault: false,
        setNewDefaultError: action.payload.message,
        authLoaders: { ...state.authLoaders, setNewAccount: false },
    }
    default:
      return state
  }
}

export default Reducer;
