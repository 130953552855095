const actions = {
    AUTH_REGISTER: 'AUTH_REGISTER',
    AUTH_REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
    AUTH_REGISTER_FAILURE: 'AUTH_REGISTER_FAILURE',

    AUTH_LOGIN: 'AUTH_LOGIN',
    AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    AUTH_LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

    AUTH_INIT_USER: 'AUTH_INIT_USER',
    AUTH_INIT_USER_SUCCESS: 'AUTH_INIT_USER_SUCCESS',
    AUTH_INIT_USER_FAILURE: 'AUTH_INIT_USER_FAILURE',

    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
    AUTH_LOGOUT_FAILURE: 'AUTH_LOGOUT_FAILURE',

    AUTH_VERIFY_EMAIL: 'AUTH_VERIFY_EMAIL',
    AUTH_VERIFY_EMAIL_SUCCESS: 'AUTH_VERIFY_EMAIL_SUCCESS',
    AUTH_VERIFY_EMAIL_FAILURE: 'AUTH_VERIFY_EMAIL_FAILURE',

    AUTH_FORGOT_PASSWORD: 'AUTH_FORGOT_PASSWORD',
    AUTH_FORGOT_PASSWORD_SUCCESS: 'AUTH_FORGOT_PASSWORD_SUCCESS',
    AUTH_FORGOT_PASSWORD_FAILURE: 'AUTH_FORGOT_PASSWORD_FAILURE',

    AUTH_RESET_PASSWORD: 'AUTH_RESET_PASSWORD',
    AUTH_RESET_PASSWORD_SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
    AUTH_RESET_PASSWORD_FAILURE: 'AUTH_RESET_PASSWORD_FAILURE',

    AUTH_SEND_EMAIL_VERIFICATION_LINK: 'AUTH_SEND_EMAIL_VERIFICATION_LINK',
    AUTH_SEND_EMAIL_VERIFICATION_LINK_SUCCESS: 'AUTH_SEND_EMAIL_VERIFICATION_LINK_SUCCESS',
    AUTH_SEND_EMAIL_VERIFICATION_LINK_FAILURE: 'AUTH_SEND_EMAIL_VERIFICATION_LINK_FAILURE',

    AUTH_SETUP_USER: 'AUTH_SETUP_USER',
    AUTH_SETUP_USER_SUCCESS: 'AUTH_SETUP_USER_SUCCESS',
    AUTH_SETUP_USER_FAILURE: 'AUTH_SETUP_USER_FAILURE',

    AUTH_SET_DEFAULT_ACCOUNT: 'AUTH_SET_DEFAULT_ACCOUNT',
    AUTH_SET_DEFAULT_ACCOUNT_SUCCESS: 'AUTH_SET_DEFAULT_ACCOUNT_SUCCESS',
    AUTH_SET_DEFAULT_ACCOUNT_FAILURE: 'AUTH_SET_DEFAULT_ACCOUNT_FAILURE',

    AUTH_GET_USER_ACCOUNT: 'AUTH_GET_USER_ACCOUNT',
    AUTH_GET_USER_ACCOUNT_SUCCESS: 'AUTH_GET_USER_ACCOUNT_SUCCESS',
    AUTH_GET_USER_ACCOUNT_FAILURE: 'AUTH_GET_USER_ACCOUNT_FAILURE',

    AUTH_SETUP_ACCOUNT_DETAILS: 'AUTH_SETUP_ACCOUNT_DETAILS',
    AUTH_SETUP_ACCOUNT_DETAILS_SUCCESS: 'AUTH_SETUP_ACCOUNT_DETAILS_SUCCESS',
    AUTH_SETUP_ACCOUNT_DETAILS_FAILURE: 'AUTH_SETUP_ACCOUNT_DETAILS_FAILURE',



    AUTH_SET_USER: 'AUTH_SET_USER',
  }
  
  export default actions;
  