import { produce } from 'immer';
import actions from '../../Checkout/Details/actions';

const initialState = {
	details: {
		name: '',
		telephone: '',
		company: '',
		email: '',
		deliveryPostcode: '',
		collectionPostcode: '',
		deliveryDate: '',
		collectionDate: '',
		delivery_time: null,
		collection_time: null,
		// deliveryDateOpened
		// deliveryDateOpened
		hireDays: 8,
		hireWeeks: 1,
		deliveryDateOptions: {
			minDate: new Date(new Date().getTime() + 86400000 * 1).toISOString().split('T')[0], //1 day in YYYY-MM-DD
			startingDay: 1,
			showWeeks: false
		},
		postcodeModifier: false,
		deliveryCost: 0,
		collectionCost: 0,
		collectionDateOptions: {
			minDate: new Date(new Date().getTime() + 86400000 * 2).toISOString().split('T')[0], //2days in YYYY-MM-DD
			startingDay: 1,
			showWeeks: false
		},
		stand: {
			hall_number: null,
			stand_number: null
		}
	},
	transport: [],
    loaders: {
		savingQuote: false
	},
	retrievingQuote: false,
    initial: true
};

function Reducer(state = initialState, action) {
  switch (action.type) {

    case actions.CHECKOUT_DETAILS_SET_DETAILS:
		return {
			...state, 
			details: {
				...state.details,
				...action.payload
			}
    }
	case actions.CHECKOUT_DETAILS_SET_KEY:
		return produce(state, (draft) => {
			draft.loaders[action.payload.key] = action.payload.value;
		})

	case actions.SET_DETAILS_KEY:
		return {
			...state, 
			...action.payload
		}


    // case actions.SET_DETAILS_FORM_SUCCESS:
    //   	return {
	// 		...state, 
	// 		...action.payload,
	// 		loaders: { ...state.loaders, setDetails: false },
    // }
    // case actions.SET_DETAILS_FORM_FAILURE:
	// 	return {
	// 		...state, loaders: { ...state.loaders, setDetails: false },
    // }

    case actions.CHECKOUT_DETAILS_RESET:
      	return initialState;
    default:
      	return state
  }
}

export default Reducer;
