import { produce } from 'immer'
import actions from '../Agent/actions';

const initialState = {
    init: true,
    dashboard: [],
}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.AGENT_LOGIN_AS_USER:
      return {
        ...state, agentLoaders: { ...state.agentLoaders, loginAsUser: true },
    }
    case actions.AGENT_LOGIN_AS_USER_SUCCESS:
      return {
        ...state, 
        loggedInAs: {
          ID: action.payload.ID,
          error: false,
          message: action.payload.message
        },
        agentLoaders: { ...state.agentLoaders, loginAsUser: false },
    }
    case actions.AGENT_LOGIN_AS_USER_FAILURE:
      return {
        ...state, 
        loggedInAs: {
          ID: false,
          error: true,
          message: action.payload.message
        },
        agentLoaders: { ...state.agentLoaders, loginAsUser: false },
    }

    case actions.GET_COUNTRY_LIST:
      return {
        ...state, 
        agentLoaders: {...state.agentLoaders, getCountries: true, },
    }
    case actions.GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state, 
        countries: action.payload,
        agentLoaders: { ...state.agentLoaders, getCountries: false },
    }
    case actions.GET_COUNTRY_LIST_FAILURE:
      return {
        ...state, 
        countries: [],
        agentLoaders: { ...state.agentLoaders, getCountries: false },
    }

    case actions.GET_SECTORS:
      return {
        ...state, 
        agentLoaders: {...state.agentLoaders, getSectors: true, },
    }
    case actions.GET_SECTORS_SUCCESS:
      return {
        ...state, 
        sectors: action.payload,
        agentLoaders: { ...state.agentLoaders, getSectors: false },
    }
    case actions.GET_SECTORS_FAILURE:
      return {
        ...state, 
        sectors: [],
        agentLoaders: { ...state.agentLoaders, getSectors: false },
    }

    case actions.AGENT_ACT_AS_CUSTOMER_ID:
      return {
        ...state,
        agentLoaders: {...state.agentLoaders, actAsCustomerID: true, },
    }
    case actions.AGENT_ACT_AS_CUSTOMER_ID_SUCCESS:
      return {
        ...state, 
        actingAsCustomerID: action.payload.customer_id,
        actingAsCustomerEmail: action.payload.email,
        agentLoaders: { ...state.agentLoaders, actAsCustomerID: false },
    }
    case actions.AGENT_ACT_AS_CUSTOMER_ID_FAILURE:
      return {
        ...state, 
        actingAsCustomerID: false,
        actingAsCustomerEmail: false,
        agentLoaders: { ...state.agentLoaders, actAsCustomerID: false },
    }

    case actions.AGENT_UPDATE_QUOTE_DATA:
      return {
        ...state,
        agentLoaders: {...state.agentLoaders, updatingQuote: true, },
    }
    case actions.AGENT_UPDATE_QUOTE_DATA_SUCCESS:
      return {
        ...state, 
        updatedQuote: action.payload,
        agentLoaders: { ...state.agentLoaders, updatingQuote: false },
    }
    case actions.AGENT_UPDATE_QUOTE_DATA_FAILURE:
      return {
        ...state, 
        updatedQuote: action.payload,
        agentLoaders: { ...state.agentLoaders, updatingQuote: false },
    }

    case actions.GET_SCHEDULED_CARRIAGES:
      return {
        ...state,
        carriage_list: [],
        agentLoaders: {...state.agentLoaders, getScheduledCarriages: true, },
    }
    case actions.GET_SCHEDULED_CARRIAGES_SUCCESS:
      return {
        ...state, 
        carriage_list: action.payload,
        agentLoaders: { ...state.agentLoaders, getScheduledCarriages: false },
    }
    case actions.GET_SCHEDULED_CARRIAGES_FAILURE:
      return {
        ...state, 
        carriage_list: action.payload,
        agentLoaders: { ...state.agentLoaders, getScheduledCarriages: false },
    }

    case actions.GET_SINGLE_CARRIAGE:
      return {
        ...state,
        single_carriage: [],
        agentLoaders: {...state.agentLoaders, get_single_carriage: true, },
    }
    case actions.GET_SINGLE_CARRIAGE_SUCCESS:
      return {
        ...state, 
        single_carriage: action.payload,
        agentLoaders: { ...state.agentLoaders, get_single_carriage: false },
    }
    case actions.GET_SINGLE_CARRIAGE_FAILURE:
      return {
        ...state, 
        single_carriage: action.payload,
        agentLoaders: { ...state.agentLoaders, get_single_carriage: false },
    }

    case actions.RESET_SINGLE_CARRIAGE:
      return {
        ...state, 
        single_carriage: [],
        agentLoaders: { ...state.agentLoaders, get_single_carriage: false },
    }

    case actions.GET_SCHEDULE_BY_WEEK:
      return {
        ...state,
        carriages_by_week: [],
        agentLoaders: {...state.agentLoaders, get_carriages_by_week: true, },
    }
    case actions.GET_SCHEDULE_BY_WEEK_SUCCESS:
      return {
        ...state, 
        carriages_by_week: action.payload,
        agentLoaders: { ...state.agentLoaders, get_carriages_by_week: false },
    }
    case actions.GET_SCHEDULE_BY_WEEK_FAILURE:
      return {
        ...state, 
        carriages_by_week: action.payload,
        agentLoaders: { ...state.agentLoaders, get_carriages_by_week: false },
    }

    case actions.GET_QUOTES_AND_ORDERS_FOR_MAP:
      return {
        ...state,
        quotes_and_orders: [],
        agentLoaders: {...state.agentLoaders, get_quotes_and_orders: true, },
    }
    case actions.GET_QUOTES_AND_ORDERS_FOR_MAP_SUCCESS:
      return {
        ...state, 
        quotes_and_orders: action.payload,
        agentLoaders: { ...state.agentLoaders, get_quotes_and_orders: false },
    }
    case actions.GET_QUOTES_AND_ORDERS_FOR_MAP_FAILURE:
      return {
        ...state, 
        quotes_and_orders: action.payload,
        agentLoaders: { ...state.agentLoaders, get_quotes_and_orders: false },
    }


    case actions.UPDATE_CONFIRMATION_CALL:
      return produce(state, (draft) => {
        draft.carriages_by_week.data[action.payload.weekDay].data[action.payload.key].confirmation_call = action.payload.new_value;
    })

    case actions.SET_USER_STATE:
      return produce(state, (draft) => {
        draft.user_state = action.payload;
    })



    //singles
    case actions.RESET_UPDATED_QUOTE:
      return {
        ...state, 
        updatedQuote: [],
    }
    

    //DASHBOARD (agent)
    case actions.SET_CUSTOMER_LIST:
      return {
        ...state, 
        dashboard: {
          ...state.dashboard,
          customer_list: action.payload.data,
          active_list: {
            title: action.payload.title,
            data: [],
        }
      },
    }
    case actions.SET_ACTIVE_CUSTOMER_IN_LIST:
      return {
        ...state, 
        dashboard: {
          ...state.dashboard,
          active_list: {
            ...state.dashboard.active_list,
            data: action.payload,
        }
      },
    }

    // case actions.SET_CUSTOMER_LIST:
    //   return produce(state, (draft) => {
    //     draft['dashboard']['customer_list'] = action.payload.data;
    //     draft['dashboard']['active_list']['title'] = action.payload.title;
    // })

    default:
      return state
  }
}

export default Reducer;
