import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from './actions'
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postCustomRequest, getRootReq, postRootReq, getBaseRequest, postBaseRequest} from '../../config/axiosClient'
import axios from 'axios';

import {topMiddleErrorAlert, topMiddleSuccessAlert} from '../Alert/apiSaga';

export const generic_error_message = 'Oops, something went wrong!';
export const getDash = (state) => state.dashboardReducer;
export const getAuth = (state) => state.authReducer;


function* getScheduleByPostCodeDeliveries(action) {
    const {
    PostCode = "",
    CarriageDate = ""
    } = action.payload

    const queryString = `agent/get-schedule-by-postcode?type=deliveries&PostCode=${PostCode}&CarriageDate=${CarriageDate}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield put({type: actions.SCHEDULE_GET_BY_POSTCODE_DELIVERIES_FAILURE, payload: response.data})
        yield call(topMiddleErrorAlert, response.data.message)
      }
      else {
        yield put({type: actions.SCHEDULE_GET_BY_POSTCODE_DELIVERIES_SUCCESS, payload: response.data})
      }

  
    } catch (error) {
        console.log(error)
    //   yield put({type: actions.GET_SINGLE_QUOTE_SECTION_DETAILS_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* getScheduleByPostCodeCollections(action) {
    const {
    PostCode = "",
    CarriageDate = ""
    } = action.payload

    const queryString = `agent/get-schedule-by-postcode?type=collections&PostCode=${PostCode}&CarriageDate=${CarriageDate}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield put({type: actions.SCHEDULE_GET_BY_POSTCODE_COLLECTIONS_FAILURE, payload: response.data})
        yield call(topMiddleErrorAlert, response.data.message)
      }
      else {
        yield put({type: actions.SCHEDULE_GET_BY_POSTCODE_COLLECTIONS_SUCCESS, payload: response.data})
      }

  
    } catch (error) {
        console.log(error)
    //   yield put({type: actions.GET_SINGLE_QUOTE_SECTION_DETAILS_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}


  

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SCHEDULE_GET_BY_POSTCODE_DELIVERIES, getScheduleByPostCodeDeliveries),
    takeLatest(actions.SCHEDULE_GET_BY_POSTCODE_COLLECTIONS, getScheduleByPostCodeCollections),
  ]);
}
