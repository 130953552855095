const actions = {

  TOP_MODAL_OPEN: 'TOP_MODAL_OPEN',
  TOP_MODAL_CLOSE: 'TOP_MODAL_CLOSE',
  
  TOP_MODAL_CHECK_STOCK: 'TOP_MODAL_CHECK_STOCK',
  MODAL_SET_PRODUCT_PRICE: 'MODAL_SET_PRODUCT_PRICE',
  MODAL_SET_ALTERNATIVE_COUNT: 'MODAL_SET_ALTERNATIVE_COUNT',
  MODAL_SET_PRODUCT_AVAILABILITY: 'MODAL_SET_PRODUCT_AVAILABILITY',
  MODAL_SET_ALTERNATIVE_PROGRESS_PERCENTAGE: 'MODAL_SET_ALTERNATIVE_PROGRESS_PERCENTAGE',
  MODAL_SET_ALTERNATIVE_CHECK_COMPLETED: 'MODAL_SET_ALTERNATIVE_CHECK_COMPLETED',
  MODAL_SET_AVAILABLE_ALTERNATIVES: 'MODAL_SET_AVAILABLE_ALTERNATIVES',

  RIGHT_DRAWER_MODAL_OPEN: 'RIGHT_DRAWER_MODAL_OPEN',
  RIGHT_DRAWER_MODAL_CLOSE: 'RIGHT_DRAWER_MODAL_CLOSE',
  CREATE_ACCOUNT_MODAL_TOGGLE: 'CREATE_ACCOUNT_MODAL_TOGGLE',
  HANDLE_TOP_CART_MODAL_TOGGLE: 'HANDLE_TOP_CART_MODAL_TOGGLE',

}

export default actions;
