import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from './actions'
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postCustomRequest, getRootReq, postRootReq, getBaseRequest, postBaseRequest} from '../../config/axiosClient'
import axios from 'axios';

import {topMiddleErrorAlert, topMiddleSuccessAlert} from '../Alert/apiSaga';

export const generic_error_message = 'Oops, something went wrong!';


function* getAgentQuoteEmailTemplates(action) {

    const type = 'quote';

    const queryString = `agent/fetch-email-templates?type=${type}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* getAgentOrderEmailTemplates(action) {

    const type = 'order';

    const queryString = `agent/fetch-email-templates?type=${type}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES, getAgentQuoteEmailTemplates),
    takeLatest(actions.GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES, getAgentOrderEmailTemplates),
  ]);
}
