import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Auth/actions'
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postCustomRequest, getRootReq, postRootReq, getBaseRequest, postBaseRequest} from '../../config/axiosClient'
import axios from 'axios';

import {topMiddleErrorAlert, topMiddleSuccessAlert} from '../Alert/apiSaga';
// import { filterTimeSlots, getEndSlot } from '../../config/helpers';

export const generic_error_message = 'Oops, something went wrong!';

export const getSessionData = (state) => state.userReducer;

function* register(action) {
    const { name, email, password, password_confirmed } = action.payload
    const payload = {
        ...action.payload,
        brand: process.env.brand.acronym
    }
    try {
        // dispatch({
        //     type: types.START_REGISTER_LOADING,
        // });
        // API Call.
        yield call(() => getRootReq('/sanctum/csrf-cookie'));
        const res = yield call(() => postBaseRequest('register', payload));


        // const res = await axios.post("/register", {
        //     name,
        //     email,
        //     password,
        //     password_confirmed,
        // });

        // Load the user if registration was successful.
        if (res.status === 201) {
            // dispatch(loadUser());
            yield put({type: actions.AUTH_INIT_USER});
            yield put({type: actions.AUTH_LOGIN_SUCCESS});
        }
    } catch (error) {
        if (error.response && error.response.status === 422) {
            const emailErrorMsg = error.response.data.errors.email[0];

            if (emailErrorMsg) {
                yield put({
                    type: actions.AUTH_REGISTER_FAILURE,
                    payload: emailErrorMsg,
                });
                yield call(topMiddleErrorAlert, emailErrorMsg)
            }
        } else {
            yield put({
                type: actions.AUTH_REGISTER_FAILURE,
                payload: "Sorry, something went wrong.",
            });
            yield call(topMiddleErrorAlert, generic_error_message)
        }
    }
};

function* login(action) {
    const {email, password} = action.payload;
    const {user_agent, ip} = yield select(getSessionData);
    try {
        // Make api requests.
        // postBaseRequest
        yield call(() => getRootReq('/sanctum/csrf-cookie'));
        const res = yield call(() => postBaseRequest('loginUser', {
            ...action.payload,
            user_agent,
            ip
        }));
        // // axios.get("/sanctum/csrf-cookie");
        // const res = axios.post("/login", {
        //     email,
        //     password,
        // });

        // Authentication was successful.
        if (res.status === 204) {
            // dispatch(loadUser());
            // yield call(initUser)
            yield put({type: actions.AUTH_INIT_USER});
            yield put({type: actions.AUTH_LOGIN_SUCCESS});
        }
    } catch (error) {
        if ((error.response) && (error.response.status === 422) || (error.response.status === 401)) {
            const errorMessage = "Invalid credentials";
            yield put({type: actions.AUTH_LOGIN_FAILURE, payload: errorMessage});

            yield put({type: 'ALERT_SET', payload: { //snackbar to display that a contact message has been sent
                open: true,
                title: errorMessage,
                severity: 'error',
                variant: 'filled',
                duration: 3000,
                vertical: 'top',
                horizontal: 'center'
            }});

        }
        else if (error.response && error.response.status === 419) {
            yield put({type: actions.AUTH_LOGIN_FAILURE, payload: 'Application access denied.'});
        } else {
            yield put({type: actions.AUTH_LOGIN_FAILURE, payload: 'Sorry, something went wrong.'});
        }
    }
};

function* initUser() {
    try {
        yield call(() => getRootReq('/sanctum/csrf-cookie'));
        const res = yield call(() => getBaseRequest('user'));

        // User was loaded successfully.
        if (res.status === 200) {
            yield put({ type: actions.AUTH_INIT_USER_SUCCESS, payload: {...res.data} });
            if(res.data.email_verified_at != null) {
                yield put({ type: actions.AUTH_GET_USER_ACCOUNT })
            }

        }
    } catch (error) {
        if (error.response.status === 422) {
            yield put({ type: actions.AUTH_INIT_USER_FAILURE, payload: 'Email or password are incorrect.' });
        } else {
            yield put({ type: actions.AUTH_INIT_USER_FAILURE, payload: 'Sorry, something went wrong.' });
        }
    }
}

function* setUser(action) {
    try {
        if(action.payload.user != null && action.payload.user != false) {
            yield put({ type: actions.AUTH_INIT_USER_SUCCESS, payload: action.payload });
            // if(action.payload.user.email_verified_at != null && action.payload.loadUser) {
            //     yield put({ type: actions.AUTH_GET_USER_ACCOUNT })
            // }
        }
        else {
            yield put({ type: actions.AUTH_INIT_USER_FAILURE, payload: 'Sorry, something went wrong.' });
        }
    } catch (error) {
        if (error.response.status === 422) {
            yield put({ type: actions.AUTH_INIT_USER_FAILURE, payload: 'Email or password are incorrect.' });
        } else {
            yield put({ type: actions.AUTH_INIT_USER_FAILURE, payload: 'Sorry, something went wrong.' });
        }
    }
}

/**
 * Log current user out.
 */
 function* logout() {
    try {
        const res = yield call(() => postBaseRequest('logout'));
        if (res.status === 204) {
            yield put({ type: actions.AUTH_LOGOUT_SUCCESS, payload: {reason: 'logout'} });
        }
    } catch (error) {
        yield put({ type: actions.AUTH_LOGOUT_FAILURE });
    }
};


function* verifyEmail(action) {
    const { userID, hash, expires, signature } = action.payload;
    try {
        /**
         * Construct the url the api expects.
         * It must be /email/verify/USERID/HASH?expires=EXPIRES&signature=SIGNATURE
         */
        const requestURL = `/email/verify/${userID}/${hash}?expires=${expires}&signature=${signature}`;

        // Send req to api.
        const res = yield call(() => getBaseRequest(requestURL));
        // Success.
        if (res.status === 204) {
            const payload = {
                success: true,
                error: "",
            };
            
            yield put({ type: actions.AUTH_VERIFY_EMAIL_SUCCESS, payload: payload });
            const userReq = yield call(() => getBaseRequest('user'));
            if (userReq.status === 200) {
                yield put({ type: actions.AUTH_INIT_USER_SUCCESS, payload: userReq.data });
                yield put({ type: actions.AUTH_SETUP_USER, payload: {id: userReq.data.user.id, email: userReq.data.user.email}})
                if(userReq.data.user.email_verified_at != null) {
                    yield put({ type: actions.AUTH_GET_USER_ACCOUNT })
                }
            }
        }
        // Error.
        else {
            const payload = {
                success: false,
                error: generic_error_message,
            };
            yield put({ type: actions.AUTH_VERIFY_EMAIL_FAILURE, payload: payload });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            const payload = {
                success: false,
                error: error.response.data.message,
            };
            yield put({ type: actions.AUTH_VERIFY_EMAIL_FAILURE, payload: payload });
        } else {
            const payload = {
                success: false,
                error: generic_error_message,
            };
            yield put({ type: actions.AUTH_VERIFY_EMAIL_FAILURE, payload: payload });
        }
    }
};

function* forgotPassword(action) {
    const {email} = action.payload;
    try {
        // const res = await axios.post("/password/email", { email });
        const res = yield call(() => postBaseRequest("password/email", {email}));

        // Behaviour on success.
        if (res.status === 200) {
            yield put({ type: actions.AUTH_FORGOT_PASSWORD_SUCCESS, payload: {
                success: res.data.message,
                error: ""
            } });
            yield call(topMiddleSuccessAlert, res.data.message)
        }
    } catch (error) {
        // Return an error message if the email was not found in the DB.
        if (error.response.status === 422) {
            // dispatch({
            //     type: types.AUTH_GENERAL_ERROR,
            // });
            const errorMessage = "There is no account registered to this email address"
            yield put({ type: actions.AUTH_FORGOT_PASSWORD_FAILURE, payload: {
                success: false,
                error: errorMessage,
            } });
            // yield call(handleAuthErrorAlert, errorMessage);
            yield call(topMiddleErrorAlert, errorMessage)
        }
    }
};

function* resetPassword(action) {
    const {email, password, token} = action.payload;
    try {

        const res = yield call(() => postBaseRequest("password/reset", {email, password, password_confirmation: password, token}));

        // Behaviour on success.
        if (res.status === 200) {
            yield put({ type: actions.AUTH_RESET_PASSWORD_SUCCESS, payload: {
                success: res.data.message,
                error: "",
            } });

            const userReq = yield call(() => getBaseRequest('user'));
            if (userReq.status === 200) {
                yield put({ type: actions.AUTH_INIT_USER_SUCCESS, payload: userReq.data });
                if(userReq.data.email_verified_at != null) {
                    yield put({ type: actions.AUTH_GET_USER_ACCOUNT })
                }
                yield call(topMiddleSuccessAlert, res.data.message)
            }

            /**
             * No need to dispatch an action here as
             * the user will be redirected, which will trigger
             * the LOAD_USER actions anyways.
             */
        }
        else {
            yield put({ type: actions.AUTH_RESET_PASSWORD_FAILURE, payload: {
                success: "",
                error: "The given data is invalid",
            } });
            yield call(topMiddleErrorAlert, 'The given link has expired, please try with a new verification link.')
        }
    } catch (error) {
        // dispatch({
        //     type: types.AUTH_GENERAL_ERROR,
        // });
        yield put({ type: actions.AUTH_RESET_PASSWORD_FAILURE, payload: {
            success: "",
            error: "The given data is invalid",
        } });
        yield call(topMiddleErrorAlert, 'The given link has expired, please try with a new verification link.')
    }
};

function* verifyEmailLink(action) {
    const email = action.payload;
    const errMessage = generic_error_message;

    try {

        const res = yield call(() => postBaseRequest("email/resend", {email: email}));

        // Behaviour on success.
        if (res.status === 202) {
            console.log(res)
            yield put({ type: actions.AUTH_SEND_EMAIL_VERIFICATION_LINK_SUCCESS, payload: {
                success: res.data.message,
                error: "",
            } });
            yield call(topMiddleSuccessAlert, 'Success! Please check your email for your new verification link.')

            /**
             * No need to dispatch an action here as
             * the user will be redirected, which will trigger
             * the LOAD_USER actions anyways.
             */
        }
        else {
            yield put({ type: actions.AUTH_SEND_EMAIL_VERIFICATION_LINK_FAILURE, payload: {
                success: "",
                error: message,
            } });
            yield call(topMiddleErrorAlert, errMessage)
        }
    } catch (error) {
        // dispatch({
        //     type: types.AUTH_GENERAL_ERROR,
        // });
        yield put({ type: actions.AUTH_SEND_EMAIL_VERIFICATION_LINK_FAILURE, payload: {
            success: "",
            error: generic_error_message
        } });
        yield call(topMiddleErrorAlert, errMessage)

    }
};

function* setupUser(action) {
    const {id, email} = action.payload;

    try {
        const res = yield call(() => postBaseRequest("setup-customer-accounts", {id: id, email: email}));
        if(res.data.status == 'success') {
            yield put({ type: actions.AUTH_SETUP_USER_SUCCESS, payload: res.data.accounts});
            yield put({ type: actions.AUTH_INIT_USER });
        }
        else {
            yield put({ type: actions.AUTH_SETUP_USER_FAILURE, payload: generic_error_message });
            yield call(topMiddleErrorAlert, generic_error_message);
        }
    } catch (error) {
        yield put({ type: actions.AUTH_SETUP_USER_FAILURE, payload: generic_error_message });
        yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* setDefaultAccount(action) {
    try {
        const res = yield call(() => postBaseRequest("set-default-account", {CustomerID: action.payload}));
        if(res.data.status == 'success') {
            yield put({ type: actions.AUTH_SET_DEFAULT_ACCOUNT_SUCCESS, payload: res.data});
            yield put({ type: actions.AUTH_GET_USER_ACCOUNT });
            yield call(topMiddleSuccessAlert, res.data.message);
        }
        else {
            yield put({ type: actions.AUTH_SET_DEFAULT_ACCOUNT_FAILURE, payload: res.data.message });
            yield call(topMiddleErrorAlert, res.data.message);
        }
    } catch (error) {
        yield put({ type: actions.AUTH_SET_DEFAULT_ACCOUNT_FAILURE, payload: generic_error_message});
        yield call(topMiddleErrorAlert, generic_error_message)
    }
}


function* getUserDefaultAccount(action) {
    try {
        const res = yield call(() => getBaseRequest("get-user-account"));
        if(res.data.status == 'success') {
            yield put({ type: actions.AUTH_GET_USER_ACCOUNT_SUCCESS, payload: res.data});
        }
        else {
            yield put({ type: actions.AUTH_GET_USER_ACCOUNT_FAILURE, payload: res.data.message });
        }
    } catch (error) {
        yield put({ type: actions.AUTH_GET_USER_ACCOUNT_FAILURE, payload: generic_error_message});
        yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* setUserAccountDetails(action) {
    try {
        const res = yield call(() => postBaseRequest("setup-user-account-details", action.payload));
        if(res.data.status == 'success') {
            yield put({ type: actions.AUTH_SETUP_ACCOUNT_DETAILS_SUCCESS, payload: res.data});
            yield put({ type: actions.AUTH_INIT_USER });
            yield put({ type: "CREATE_ACCOUNT_MODAL_TOGGLE", payload: false});
            yield call(topMiddleSuccessAlert, res.data.message);
        }
        else {
            yield put({ type: actions.AUTH_SETUP_ACCOUNT_DETAILS_FAILURE, payload: res.data.message });
            yield call(topMiddleErrorAlert, res.data.message);
        }
    } catch (error) {
        yield put({ type: actions.AUTH_SETUP_ACCOUNT_DETAILS_FAILURE, payload: generic_error_message});
        yield call(topMiddleErrorAlert, generic_error_message)
    }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.AUTH_REGISTER, register),
            takeLatest(actions.AUTH_LOGIN, login),
            takeLatest(actions.AUTH_INIT_USER, initUser),
            takeLatest(actions.AUTH_SET_USER, setUser),
            takeLatest(actions.AUTH_LOGOUT, logout),
            takeLatest(actions.AUTH_VERIFY_EMAIL, verifyEmail),
            takeLatest(actions.AUTH_FORGOT_PASSWORD, forgotPassword),
            takeLatest(actions.AUTH_RESET_PASSWORD, resetPassword),
            takeLatest(actions.AUTH_SEND_EMAIL_VERIFICATION_LINK, verifyEmailLink),
            takeLatest(actions.AUTH_SETUP_USER, setupUser),
            takeLatest(actions.AUTH_SET_DEFAULT_ACCOUNT, setDefaultAccount),
            takeLatest(actions.AUTH_SETUP_ACCOUNT_DETAILS, setUserAccountDetails),
            takeLatest(actions.AUTH_GET_USER_ACCOUNT, getUserDefaultAccount)])
    }
