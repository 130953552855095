const actions = {
    TRACKING_GET: 'TRACKING_GET',
    TRACKING_GET_SUCCESS: 'TRACKING_GET_SUCCESS',
    TRACKING_GET_FAILURE: 'TRACKING_GET_FAILURE',
    TRACKING_SET: 'TRACKING_SET',
    TRACKING_SET_SUCCESS: 'TRACKING_SET_SUCCESS',
    TRACKING_SET_FAILURE: 'TRACKING_SET_FAILURE',

    //singles
    TRACKING_INVALID: 'TRACKING_INVALID',
    TRACKING_RESET: 'TRACKING_RESET',
}

export default actions;
