import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from './actions'
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postCustomRequest, getRootReq, postRootReq, getBaseRequest, postBaseRequest} from '../../config/axiosClient'
import axios from 'axios';

import {topMiddleErrorAlert, topMiddleSuccessAlert} from '../Alert/apiSaga';

export const generic_error_message = 'Oops, something went wrong!';
export const getDash = (state) => state.dashboardReducer;
export const getAuth = (state) => state.authReducer;


function* updateCustomerCommLog(action) {
    const queryString = `agent/update-customer-comm-log`;

    console.log(queryString)

    try {
      const response = yield call(() => postBaseRequest(queryString, action.payload)); 
      console.log(response)
      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.UPDATE_CUSTOMER_COMM_LOG_SUCCESS, payload: response.data})
  
    } catch (error) {
        console.log(error)
    //   yield put({type: actions.GET_SINGLE_QUOTE_SECTION_DETAILS_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* getListOfPreferences(action) {
    const queryString = `get-list-of-preferences`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 
      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_LIST_OF_PREFERENCES_SUCCESS, payload: response.data})
  
    } catch (error) {
        console.log(error)
    //   yield put({type: actions.GET_SINGLE_QUOTE_SECTION_DETAILS_FAILURE, payload: response.data})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

  

export default function* rootSaga() {
  yield all([
    takeLatest(actions.UPDATE_CUSTOMER_COMM_LOG, updateCustomerCommLog),
    takeLatest(actions.GET_LIST_OF_PREFERENCES, getListOfPreferences),
  ]);
}
