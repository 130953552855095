const actions = {
    ALERT_SET: 'ALERT_SET',
    ALERT_RESET: 'ALERT_RESET',
    TOP_MIDDLE_ERROR_ALERT: 'TOP_MIDDLE_ERROR_ALERT',
    TOP_MIDDLE_SUCCESS_ALERT: 'TOP_MIDDLE_SUCCESS_ALERT',
    TOP_MIDDLE_WARNING_ALERT: 'TOP_MIDDLE_WARNING_ALERT',
    TOP_MIDDLE_INFO_ALERT: 'TOP_MIDDLE_INFO_ALERT',
    CUSTOM_ALERT: "CUSTOM_ALERT"
}

export default actions;
