const actions = {
  USER_INITIALIZE_DATA: 'USER_INITIALIZE_DATA',
  USER_INITIALIZE_DATA_SUCCESS: 'USER_INITIALIZE_DATA_SUCCESS',
  USER_INITIALIZE_DATA_FAILURE: 'USER_INITIALIZE_DATA_FAILURE',

  SET_TOKEN: 'SET_TOKEN',
  SET_TOKEN_SUCCESS: 'SET_TOKEN_SUCCESS',
  SET_TOKEN_FAILURE: 'SET_TOKEN_FAILURE',
}

export default actions;
