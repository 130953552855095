const actions = {
    USERJOURNEY_SAVE_DATA: "USERJOURNEY_SAVE_DATA",
    USERJOURNEY_SAVE_DATA_SUCCESS: "USERJOURNEY_SAVE_DATA_SUCCESS",
    USERJOURNEY_SAVE_DATA_FAILURE: "USERJOURNEY_SAVE_DATA_FAILURE",

    USERJOURNEY_ENTRY_SAVE_DATA: "USERJOURNEY_ENTRY_SAVE_DATA",
    USERJOURNEY_ENTRY_SAVE_DATA_SUCCESS: "USERJOURNEY_ENTRY_SAVE_DATA_SUCCESS",
    USERJOURNEY_ENTRY_SAVE_DATA_FAILURE: "USERJOURNEY_ENTRY_SAVE_DATA_FAILURE",

    USERJOURNEY_SET: "USERJOURNEY_SET",
    USERJOURNEY_SET_SUCCESS: "USERJOURNEY_SET_SUCCESS",
    USERJOURNEY_SET_FAILURE: "USERJOURNEY_SET_FAILURE",
};

export default actions;
