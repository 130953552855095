const actions = {
  DEFAULT_DISPATCH: 'DEFAULT_DISPATCH',
  DEFAULT_DISPATCH_SUCCESS: 'DEFAULT_DISPATCH_SUCCESS',
  DEFAULT_DISPATCH_FAILURE: 'DEFAULT_DISPATCH_FAILURE',

  GLOBAL_INIT_VARIABLES: 'GLOBAL_INIT_VARIABLES',
  GLOBAL_INIT_VARIABLES_SUCCESS: 'GLOBAL_INIT_VARIABLES_SUCCESS',
  GLOBAL_INIT_VARIABLES_FAILURE: 'GLOBAL_INIT_VARIABLES_FAILURE',

    
  GLOBAL_GET_TEMPLATE_SETTINGS: 'GLOBAL_GET_TEMPLATE_SETTINGS',
  GLOBAL_GET_TEMPLATE_SETTINGS_SUCCESS: 'GLOBAL_GET_TEMPLATE_SETTINGS_SUCCESS',
  GLOBAL_GET_TEMPLATE_SETTINGS_FAILURE: 'GLOBAL_GET_TEMPLATE_SETTINGS_FAILURE',

  GLOBAL_SET_GLOBAL_PROPS: 'GLOBAL_SET_GLOBAL_PROPS',
  GLOBAL_SET_GLOBAL_PROPS_SUCCESS: 'GLOBAL_SET_GLOBAL_PROPS_SUCCESS',
  GLOBAL_SET_GLOBAL_PROPS_FAILURE: 'GLOBAL_SET_GLOBAL_PROPS_FAILURE',

  GET_BRAND_ENVIRONMENT_DATA: 'GET_BRAND_ENVIRONMENT_DATA',
  GET_BRAND_ENVIRONMENT_DATA_SUCCESS: 'GET_BRAND_ENVIRONMENT_DATA_SUCCESS',
  GET_BRAND_ENVIRONMENT_DATA_FAILURE: 'GET_BRAND_ENVIRONMENT_DATA_FAILURE',
  
  DELIVERY_CHECK_MANDITORY_SERVICE: 'DELIVERY_CHECK_MANDITORY_SERVICE',
  DELIVERY_CHECK_MANDITORY_SERVICE_SUCCESS: 'DELIVERY_CHECK_MANDITORY_SERVICE_SUCCESS',
  DELIVERY_CHECK_MANDITORY_SERVICE_FAILURE: 'DELIVERY_CHECK_MANDITORY_SERVICE_FAILURE',

  DELIVERY_GET_INSTALL_DERIG: 'DELIVERY_GET_INSTALL_DERIG',
  DELIVERY_GET_INSTALL_DERIG_SUCCESS: 'DELIVERY_GET_INSTALL_DERIG_SUCCESS',
  DELIVERY_GET_INSTALL_DERIG_FAILURE: 'DELIVERY_GET_INSTALL_DERIG_FAILURE',
  
  DELIVERY_UPDATE_TRANSPORT: 'DELIVERY_UPDATE_TRANSPORT',
  DELIVERY_UPDATE_TRANSPORT_SUCCESS: 'DELIVERY_UPDATE_TRANSPORT_SUCCESS',
  DELIVERY_UPDATE_TRANSPORT_FAILURE: 'DELIVERY_UPDATE_TRANSPORT_FAILURE',

  DELIVERY_SET_INSTALL_OR_DERIG: 'DELIVERY_SET_INSTALL_OR_DERIG',
  DELIVERY_SET_INSTALL_OR_DERIG_SUCCESS: 'DELIVERY_SET_INSTALL_OR_DERIG_SUCCESS',
  DELIVERY_SET_INSTALL_OR_DERIG_FAILURE: 'DELIVERY_SET_INSTALL_OR_DERIG_FAILURE',

  SET_TRANSPORT_DETAILS: 'SET_TRANSPORT_DETAILS',
  SET_TRANSPORT_DETAILS_SUCCESS: 'SET_TRANSPORT_DETAILS_SUCCESS',
  SET_TRANSPORT_DETAILS_FAILURE: 'SET_TRANSPORT_DETAILS_FAILURE',

  SET_PAGE_CONTENT: 'SET_PAGE_CONTENT',
  SET_PAGE_CONTENT_SUCCESS: 'SET_PAGE_CONTENT_SUCCESS',
  SET_PAGE_CONTENT_FAILURE: 'SET_PAGE_CONTENT_FAILURE',

    
  //SINGLES
  SET_PAGE_SPINNER: 'SET_PAGE_SPINNER',
  GLOBAL_SWITCH_VAT_TOGGLE: 'GLOBAL_SWITCH_VAT_TOGGLE',
  GLOBAL_SET_TAX: 'GLOBAL_SET_TAX',
  SET_HIRE_DATE_RANGE: 'SET_HIRE_DATE_RANGE',
  GLOBAL_SET_KEY: 'GLOBAL_SET_KEY',
  SET_BREADCRUMBS: "SET_BREADCRUMBS",
  SET_SIDENAV: "SET_SIDENAV",
  SET_GLOBAL_DATA: "SET_GLOBAL_DATA",


}

export default actions;
