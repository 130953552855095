const actions = {
    BUCKET_RESET_CART: 'BUCKET_RESET_CART',
    RESET_ADDON_PRODUCT: "RESET_ADDON_PRODUCT",
    BUCKET_UPDATE_CART: 'BUCKET_UPDATE_CART',
    BUCKET_UPDATE_CART_COUNTER: 'BUCKET_UPDATE_CART_COUNTER',
    BUCKET_UPDATE_CART_PRODUCT_ROW: 'BUCKET_UPDATE_CART_PRODUCT_ROW',
    BUCKET_SET_CART_LOADER: 'BUCKET_SET_CART_LOADER',
    BUCKET_SET_UPDATING_PRICES_LOADER: 'BUCKET_SET_UPDATING_PRICES_LOADER',
    CART_UPDATE_KEY: 'CART_UPDATE_KEY',
    SET_CART_ADDON_PRODUCT: 'SET_CART_ADDON_PRODUCT',
    SET_CART_RETRIEVE_QUOTE_LOADER: "SET_CART_RETRIEVE_QUOTE_LOADER",
    SET_CART_INDEX_DATA: "SET_CART_INDEX_DATA"
}

export default actions;
