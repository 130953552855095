const actions = {
  DELIVERY_GET_SURCHARGES: "DELIVERY_GET_SURCHARGES",
  DELIVERY_GET_SURCHARGES_SUCCESS: "DELIVERY_GET_SURCHARGES_SUCCESS",
  DELIVERY_GET_SURCHARGES_FAILURE: "DELIVERY_GET_SURCHARGES_FAILURE",

  DELIVERY_GET_INSTALL_DERIG: "DELIVERY_GET_INSTALL_DERIG",
  DELIVERY_GET_INSTALL_DERIG_SUCCESS: "DELIVERY_GET_INSTALL_DERIG_SUCCESS",
  DELIVERY_GET_INSTALL_DERIG_FAILURE: "DELIVERY_GET_INSTALL_DERIG_FAILURE",

  CHECKOUT_DELIVERY_SET_ALL: "CHECKOUT_DELIVERY_SET_ALL",
  CHECKOUT_DELIVERY_SET_KEY: "CHECKOUT_DELIVERY_SET_KEY",
  CHECKOUT_DELIVERY_SET_LOADER: "CHECKOUT_DELIVERY_SET_LOADER",
  CHECKOUT_DELIVERY_RESET: "CHECKOUT_DELIVERY_RESET",
}

export default actions;
