import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Success/actions';
import Bugsnag from '@bugsnag/js';
import { getRequest } from '@components/config/axiosClient';


function* getCountryList(action) {
  try {
    const response = yield call(() => getRequest('doris/countries')); 
    yield put({type: actions.CHECKOUT_SUCCESS_GET_COUNTRIES_SUCCESS, payload: response.data})
  } catch (error) {
    // console.log(error)
    Bugsnag.notify(error)
    yield put({type: actions.CHECKOUT_SUCCESS_GET_COUNTRIES_FAILURE});
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.CHECKOUT_SUCCESS_GET_COUNTRIES, getCountryList)]);
}
