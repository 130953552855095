import { all, call, put, takeLatest, select } from "redux-saga/effects";
import actions from "./actions";
import {
    postRequest,
    postBaseRequest,
    getCustomRequest,
    getRequest,
    deleteRequest,
    getBaseRequest,
} from "../../config/axiosClient";
import CacheFactory from "../../Factories/CacheFactory";

export const getCart = (state) => state.cartManagementReducer.cart;
export const getQuote = (state) => state.checkoutQuoteReducer.quote;
export const getAddress = (state) => state.checkoutPaymentReducer.address;
export const getTransport = (state) => state.checkoutDeliveryReducer.transport;


function* getStripeKey(action) {
    try {
        const cart = yield select(getCart);
        const quote = yield select(getQuote);
        const address = yield select(getAddress);
        const transport = yield select(getTransport);

        const response = yield call(() =>
            getBaseRequest("init-elements")
        );

        if (response.data) {
            yield put({
                type: actions.SUBMIT_STRIPE_KEY,
                payload: response.data.key,
            });
        } else {
            yield put({
                type: actions.SUBMIT_STRIPE_KEY,
                payload: null,
            });
        }
    } catch (error) {
        // console.log(error)
        if (error.response.status === 401) {
            message.error(error.response.data.message);
        } else {
            message.error("Something Went Wrong");
        }
    }
}


function* createCustomer(action) {
    try {

        const response = yield call(() =>
            postBaseRequest('checkout/create-customer', action.payload)
        );

        // const customer_id = json.customer_id;
        // const paymentIntentTokenData = Object.assign({}, data, {data: data, customer_id: customer_id});
        // yield put({
        //     type: actions.STRIPE_CREATE_CUSTOMER,
        //     payload: paymentIntentTokenData,
        // });
        // yield put({
        //     type: actions.PAYMENT_INTENT,
        //     payload: paymentIntentTokenData,
        // });

    } catch (error) {
        if (error.response.status === 401) {
            // message.error(error.response.data.message);
        } else {
            // message.error("Something Went Wrong");
        }
    }
}
function* createPaymentIntent(action) {
    try {

        const response = yield call(() =>
            postBaseRequest('create-intent', action.payload.details)
        );

        if (response.data) {
            yield put({
                type: actions.PAYMENT_INTENT_SUCCESS,
                payload: {secret: response.data, data: action.payload.quoteData},
            });
        } else {
            yield put({
                type: actions.PAYMENT_INTENT_SUCCESS,
                payload: null,
            });
        }
    } catch (error) {
        if (error.response.status === 401) {
            // message.error(error.response.data.message);
        } else {
            // message.error("Something Went Wrong");
        }
    }
}


export default function* rootSaga() {
    yield all([
        takeLatest("STRIPE_CREATE_CUSTOMER", createCustomer),
        takeLatest("PAYMENT_INTENT", createPaymentIntent),
        takeLatest("INIT_STRIPE", getStripeKey),
    ]);
}
