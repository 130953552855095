import actions from '../Jobs/actions';
import {produce} from 'immer'

const initialState = {
    jobLoaders: {
      getJobs: false,
    },
    jobs: [],
    states: [],
    
}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_JOBS:
      return produce(state, (draft) => {
        // draft.cart.totalWeight = action.payload;
        draft.jobLoaders.getJobs = true;
    })
    case actions.GET_JOBS_SUCCESS:
      return produce(state, (draft) => {
        draft.jobs = action.payload;
        draft.jobLoaders.getJobs = false;
    })
    case actions.GET_JOBS_FAILURE:
      return produce(state, (draft) => {
        draft.jobs = action.payload;
        draft.jobLoaders.getJobs = false;
    })

    default:
      return state
  }
}

export default Reducer;
