import { all } from "redux-saga/effects";
import globalSaga from "./Global/apiSaga";
import userSaga from "./User/apiSaga";
// import cartSaga from "./Cart/apiSaga";
// import detailsSaga from "./Details/apiSaga";
// import quoteSaga from "./Quote/apiSaga";
// import deliverySaga from "./Delivery/apiSaga";
// import paymentSaga from "./Payment/apiSaga";
import modalsSaga from "./Modals/apiSaga";
import alertSaga from "./Alert/apiSaga";
import contactSaga from "./Contact/apiSaga";
import snackbarSaga from "./Snackbar/apiSaga";
import userjourneySaga from "./UserJourney/apiSaga";
import trackingSaga from "./Tracking/apiSaga";
import stripeSaga from "./Stripe/apiSaga";
import payPalSaga from "./PayPal/apiSaga";
// import checkoutSuccessSaga from "./CheckoutSuccess/apiSaga";
import authSaga from "./Auth/apiSaga";
import agentSaga from "./Agent/apiSaga";
import dashboardSaga from "./Dashboard/apiSaga";
import dashboardSagaQuotes from "./Dashboard/apiSagaQuote";
import dashboardSagaOrders from "./Dashboard/apiSagaOrder";
import dashboardSagaDashboard from "./Dashboard/apiSagaDashboard";
import dashboardSagaSchedule from "./Dashboard/apiSagaSchedulePostCode";
import dashboardSagaPreferences from "./Dashboard/apiSagaPreferences";
import jobsSaga from "./Jobs/apiSaga";

import checkoutDeliverySaga from "./Checkout/Delivery/apiSaga";
import checkoutPaymentSaga from "./Checkout/Payment/apiSaga";
import checkoutSuccess2Saga from "./Checkout/Success/apiSaga";

// Here you can include all the saga which you write for components
export default function* rootSaga() {
    yield all([
        globalSaga(),
        userSaga(),
        // cartSaga(),
        // detailsSaga(),
        // quoteSaga(),
        // deliverySaga(),
        // paymentSaga(),
        modalsSaga(),
        alertSaga(),
        contactSaga(),
        snackbarSaga(),
        userjourneySaga(),
        trackingSaga(),
        stripeSaga(),
        payPalSaga(),
        authSaga(),
        // checkoutSuccessSaga(),
        agentSaga(),
        dashboardSaga(),
        dashboardSagaQuotes(),
        dashboardSagaOrders(),
        dashboardSagaDashboard(),
        dashboardSagaSchedule(),
        dashboardSagaPreferences(),
        jobsSaga(),

        checkoutDeliverySaga(),
        checkoutPaymentSaga(),
        checkoutSuccess2Saga(),
    ]);
}
