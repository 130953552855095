import actions from '../../Checkout/Success/actions';


const initialState = {
	parkingRestrictions: ['None', 'Single Yellow', 'Double Yellow', 'Bus Lane', 'Own Loading Bay', 'Street Loading Bay'],
	floors: ['Ground', '1st Floor', '2nd Floor', '3rd Floor +'],
	checklistSaved: false,
	address: {
		billing: null,
		delivery: null,
		collection: null
	},
	isSame: {
		checklistSame: true,
		deliverySameBilling: true,
		collectionSameDelivery: true,
	},
	craftyResults: {
		billing: {
			foundAddresses: [],
			selectedAddress: 'default',
			error: null
		},
		delivery: {
			foundAddresses: [],
			selectedAddress: 'default',
			error: null
		},
		collection: {
			foundAddresses: [],
			selectedAddress: 'default',
			error: null
		}
	},
  
	checklist: {
		delivery: {
			lift: 'no',
			liftFit: 'no',
			floor: 'Ground',
			parkingRestrictions: 'None',
			additionalInfo: ''
		},
		collection: {
			lift: 'no',
			liftFit: 'no',
			floor: 'Ground',
			parkingRestrictions: 'None',
			additionalInfo: ''
		}
	},

	countries: [],

	successLoaders: {
		successInit: true,
		getCountries: true,
		savingChecklist: false,
	}
};

function Reducer(state = initialState, action) {
  switch (action.type) {

    case actions.CHECKOUT_SUCCESS_SET_SUCCESS:
		return {
			...state, 
			...action.payload
		
    }
	case actions.CHECKOUT_SUCCESS_SET_KEY:
		return {
			...state, 
			[action.payload.key]: {
				...state[action.payload.key],
				...action.payload.value
			}
    }

	case actions.CHECKOUT_SUCCESS_SET_LOADING:
		return {
			...state, 
			successLoaders: {
				...state.successLoaders,
				[action.payload.key]: action.payload.value
			}
    }

	case actions.CHECKOUT_SUCCESS_SET_ADDRESS_TYPE:
		return {
			...state, 
			address: {
				...state.address,
				[action.payload.key]: {
					...state.address[action.payload.key],
					...action.payload.value
				}
			}
    }

	case actions.CLEAR_FOUND_ADDRESSES:
		return {
			...state, 
			craftyResults: {
				...state.craftyResults,
				[action.payload.key]: {
					...state.craftyResults[action.payload.key],
					foundAddresses: [],
				}
			}
    }
	
	case actions.CHECKOUT_SUCCESS_SET_CRAFTY_TYPE:
		return {
			...state, 
			craftyResults: {
				...state.craftyResults,
				[action.payload.key]: {
					...state.craftyResults[action.payload.key],
					...action.payload.value
				}
			}
    }

	case actions.CHECKOUT_SUCCESS_SET_CHECKLIST_TYPE:
		return {
			...state, 
			checklist: {
				...state.checklist,
				[action.payload.key]: {
					...state.checklist[action.payload.key],
					...action.payload.value
				}
			}
    }

	case actions.CHECKOUT_SUCCESS_GET_COUNTRIES:
		return {
		  ...state, 
		  successLoaders: {...state.successLoaders, getCountries: true, },
	  }
	  case actions.CHECKOUT_SUCCESS_GET_COUNTRIES_SUCCESS:
		return {
		  ...state, 
		  countries: action.payload,
		  successLoaders: { ...state.successLoaders, getCountries: false },
	  }
	  case actions.CHECKOUT_SUCCESS_GET_COUNTRIES_FAILURE:
		return {
		  ...state, 
		  countries: null,
		  successLoaders: { ...state.successLoaders, getCountries: false },
	  }


    case actions.CHECKOUT_SUCCESS_RESET:
      	return initialState;
    default:
      	return state
  }
}

export default Reducer;
