import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Global/actions';
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, getBaseRequest} from '../../config/axiosClient'
import { filterEndSlots, filterTimeSlots, getEndSlot } from '../../config/helpers';

// export const getCart = (state) => state.cartReducer.cart;
// export const getQuote = (state) => state.quoteReducer.quote;

function* DEFAULT(action) {
  try {
    yield put({type: actions.DEFAULT_DISPATCH_SUCCESS, payload: action.payload});
  } catch (error) {
    yield put({type: actions.DEFAULT_DISPATCH_FAILURE, payload: error});
    //TODO: Create a dispatch event which toggles an alert
    
  }
}

function* initVariables(action) {
  try {
    yield put({type: actions.GLOBAL_INIT_VARIABLES_SUCCESS, payload: action.payload});
    
  } catch (error) {
      yield put({type: actions.GLOBAL_INIT_VARIABLES_FAILURE, payload: 'Failed to insert global settings.'});
  }
}

function* getTemplateSettings(action) {
  try {
    const response = yield call(() => getBaseRequest('get-global-template-settings'));
    yield put({type: actions.GLOBAL_GET_TEMPLATE_SETTINGS_SUCCESS, payload: response.data});
  } catch (error) {
    yield put({type: actions.GLOBAL_GET_TEMPLATE_SETTINGS_FAILURE, payload: error});
  }
}


function* setGlobalProps(action) {
  try {
    const payload = action.payload;
    const globalProps = {
        globalProps: {
          brand: payload.brand, 
          customer_service_score: payload.customer_service_score,
          global_settings: payload.global_settings,
          prettified_categories: payload.prettified_categories,
          trustpilot_details: payload.trustpilot_details,
        },
        templateSettings: {}
    }
    yield put({type: actions.GLOBAL_SET_GLOBAL_PROPS_SUCCESS, payload: globalProps});
  } catch (error) {
    yield put({type: actions.GLOBAL_SET_GLOBAL_PROPS_FAILURE, payload: error});
  }
}


function* getBrandEnvironmentData(action) {
  try {
    const response = yield call(() => getRequest('get-brand-environment-data'));
    yield put({type: actions.GET_BRAND_ENVIRONMENT_DATA_SUCCESS, payload: response.data});
  } catch (error) {
    yield put({type: actions.GET_BRAND_ENVIRONMENT_DATA_FAILURE, payload: error});
  }
}


function* setTransportDetails(action) {
  try {
    yield put({type: actions.SET_TRANSPORT_DETAILS_SUCCESS, payload: action.payload});
  } catch (error) {
    yield put({type: actions.SET_TRANSPORT_DETAILS_FAILURE, payload: error});
  }
}

function* checkManditoryService(action) { //function to check if install/derig is required for any product in the cart
  const cart = yield select(getCart);

  try {
    const response = yield call(() => postRequest('quote/check-mandatory-service', {cart: cart.content} )); 
    let data = {serviceLock: false}; //set default manditory to false
    if(response.data.mandatoryService){ //if we have any manditory products -> set the serviceLock to true and install/derig to 'yes'
      data = {
        ...data,
        serviceLock: true,
        transport: {
          delivery: {
            install: 'yes',
          },
          collection: {
            derig: 'yes'
          }
        },
      }
    }
    else {
      data = {
        ...data,
        serviceLock: false,
        transport: {
          delivery: {
            install: 'no',
          },
          collection: {
            derig: 'no'
          }
        },
      }
    }
    yield put({type: actions.DELIVERY_CHECK_MANDITORY_SERVICE_SUCCESS, payload: data});
  } catch (error) {
    yield put({type: actions.DELIVERY_CHECK_MANDITORY_SERVICE_FAILURE, payload: error});
  }
}

function* setInstallOrDerig(action) { //function to set either install or derig, based on checkbox selected
  try {
    let data = {};
    if(action.payload.type == 'delivery'){ //if the type is delivery, set the install state
      data = {
        ...data,
        install: action.payload.state
      }
    }
    else { //otherwise if the type is collection, set the derig state
      data = {
        ...data,
        derig: action.payload.state
      }
    }
    yield put({type: actions.DELIVERY_SET_INSTALL_OR_DERIG_SUCCESS, payload: {data: data, type: action.payload.type} });
  } catch (error) {
    yield put({type: actions.DELIVERY_SET_INSTALL_OR_DERIG_FAILURE, payload: error});
  }
}

// function* getInstallDerig(action) { //function to get the install/derig pricing
//   const quote = yield select(getQuote);

//   try {
//     const response = yield call(() => getRequest('quote/transport?brand=' + process.env.brand.brand_key + '&delivery-postcode=' + quote.deliveryPostcode + '&collection-postcode=' + quote.collectionPostcode + '&cart-total-weight=' + quote.cartTotalWeight)); 
//     yield put({type: actions.DELIVERY_GET_INSTALL_DERIG_SUCCESS, payload: response.data});
//   } catch (error) {
//     yield put({type: actions.DELIVERY_GET_INSTALL_DERIG_FAILURE, payload: error});
//     //TODO: Create a dispatch event which toggles an alert
//   }
// }

function* setPageContent(action) { //function to get the install/derig pricing

  try {
    yield put({type: actions.SET_PAGE_CONTENT_SUCCESS, payload: action.payload});
  } catch (error) {
    yield put({type: actions.SET_PAGE_CONTENT_FAILURE, payload: error});
    //TODO: Create a dispatch event which toggles an alert
  }
}

function* updateTransport(action) {
  const quote = yield select(getQuote); //get the quote
  const transport = action.payload.transport; //get the transport object
  const type = action.payload.type //get the type [collection, delivery]

  let timeStart; //time start e.g 08:00
  let timeEnd; //time end e.g 18:00
  let timeSlots;

  try {
    if(!transport.option){ //if the default has been selected, set the default times of 08:00 - 18:00
        timeStart = '08:00';
        timeEnd = '18:00';
        timeSlots = []
    }
    else {
      timeStart = transport.start_time; //otherwise we set the start time to what is defined in the object
      /**
       * Specified transport timeslots do not have a default end time, but if any other option has been selected
       * Then we set the timneslots to the inside working hours and set the end time to what is defined in the object
       */
      if(transport.option.end_time) { 
        timeSlots = process.env.insideWorkingHours;
        timeEnd = transport.end_time;
      }
      else { //If it is a specified timeslot and within inside working hours, filter the timeslots based on working hours, and duration
        if(transport.option.inside_working_hours == 'yes'){
          timeSlots = filterTimeSlots(process.env.insideWorkingHours, transport.option.duration);
        }
        else { //otherwise set the timeslots to outside working hours
          timeSlots = filterEndSlots()
         
          //loop through and check if the outside working hour is within the inside working hour
        }
        if(timeStart == null) { //if there is no start time, set it to the default
          timeStart = type == 'delivery' ? '08:00' : '07:00'
        }
        timeEnd = getEndSlot(timeStart, transport.option.duration) //then get the timeslots based on the default
      }
    }
  
    const payload = { //define the payload based on the code above
      option: transport.option ? transport.option : null,
      type: type,
      timeStart,
      timeEnd,
      timeSlots,
    }

  yield put({type: actions.DELIVERY_UPDATE_TRANSPORT_SUCCESS, payload: payload}); //store the transport data into the state
    
  } catch (error) {
    yield put({type: actions.DELIVERY_UPDATE_TRANSPORT_FAILURE, payload: error});
    
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.DEFAULT_DISPATCH, DEFAULT)]);
  yield all([takeLatest(actions.GLOBAL_INIT_VARIABLES, initVariables)]);
  yield all([takeLatest(actions.GLOBAL_GET_TEMPLATE_SETTINGS, getTemplateSettings)]);
  yield all([takeLatest(actions.GLOBAL_SET_GLOBAL_PROPS, setGlobalProps)]);
  yield all([takeLatest(actions.GET_BRAND_ENVIRONMENT_DATA, getBrandEnvironmentData)]);
  yield all([takeLatest(actions.SET_TRANSPORT_DETAILS, setTransportDetails)]);
  yield all([takeLatest(actions.DELIVERY_CHECK_MANDITORY_SERVICE, checkManditoryService)]);
  // yield all([takeLatest(actions.DELIVERY_GET_INSTALL_DERIG, getInstallDerig)]);
  yield all([takeLatest(actions.DELIVERY_UPDATE_TRANSPORT, updateTransport)]);
  yield all([takeLatest(actions.DELIVERY_SET_INSTALL_OR_DERIG, setInstallOrDerig)]);
  yield all([takeLatest(actions.SET_PAGE_CONTENT, setPageContent)]);
}
