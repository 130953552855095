const actions = {
    

    STRIPE_CREATE_CUSTOMER: "STRIPE_CREATE_CUSTOMER",
    STRIPE_CREATE_CUSTOMER_SUCCESS: "STRIPE_CREATE_CUSTOMER_SUCCESS",
    STRIPE_CREATE_CUSTOMER_FAILURE: "STRIPE_CREATE_CUSTOMER_FAILURE",

    INIT_STRIPE: "INIT_STRIPE",
    SUBMIT_STRIPE_KEY: "SUBMIT_STRIPE_KEY",

    PAYMENT_INTENT: "PAYMENT_INTENT",
    PAYMENT_INTENT_SUCCESS: "PAYMENT_INTENT_SUCCESS",
    CLEAR_PAYMENT_INTENT: "CLEAR_PAYMENT_INTENT",
    SET_PAYMENT_ERROR: "SET_PAYMENT_ERROR",
    STRIPE_RESET: "STRIPE_RESET"
};

export default actions;
