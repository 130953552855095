import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Jobs/actions';
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, getBaseRequest} from '../../config/axiosClient'
import { filterEndSlots, filterTimeSlots, getEndSlot } from '../../config/helpers';


function* getJobs(action) {
  const data = action.payload;
  try {
    let queryStr = "";
    Object.keys(data).map((key, i) => {
      if(key){
        queryStr += i == 0 ? '?' : '&';

        //key val
        queryStr += `${key}=${data[key]}`
      }
    });

    const response = yield call(() => getBaseRequest('dashboard/jobs/get-list-of-jobs' + queryStr));
    yield put({type: actions.GET_JOBS_SUCCESS, payload: response.data});
  } catch (error) {
    yield put({type: actions.GET_JOBS_FAILURE, payload: error});
    //TODO: Create a dispatch event which toggles an alert
    
  }
}


export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_JOBS, getJobs),
  ]);
}
