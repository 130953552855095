import Container from "@mui/material/Container";
import dayjs from 'dayjs';
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextInput from "../Custom/Inputs/TextInput";

//Capitalise First Letter In A String
export const capitalizeFirstLetter = (string) => {
    return string != null && string.length > 0 ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const capitalizeEveryWord = (string) => {
    if(typeof string == 'undefined' || string == null) return '';
    const str = string.trim();
    const words = str.replace(/  +/g, ' ').split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i]?.[0]?.toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
};

export const removeHyphens = (string) => {
    return string.split("-").join(" ");
};
export const trimSp = (string) => {
    return string.split(" ").join("");
};

export const removeQueryString = (string) => {
    return string.split('?')[0];
}
export const removeAfterSpace = (string) => {
    return string.split(" ")[0];
}

export const removeLastLetter = (string) => {
    return string.substring(0, string.length-1);
}


//helper function for safely checking variable's assignment
export const isDefined = (value) => {
    return typeof value !== "undefined" && value ? true : false;
};

export const isTrue = (value) => {
    return value == true ? true : false;
};

export const isNull = (value) => {
    return value == null ? true : false;
};

//helper function for safely checking variable's assignment
export const count = (value) => {
    return value.length;
};

//helper function for checking if value is in array
export const inArray = (needle, haystack) => {
    let found = false;
    // values.forEach((x, i) => {
    //     console.log(x)
    //     if(array.includes(x)) {
    //         found = true;
    //     }
    // })
    // console.log(array)
    if (haystack.includes(needle)) {
        found = true;
    }
    return found;
};

export const noValue = (value) => {
    try {
        if(
            !isDefined(value) ||
                isNull(value) ||
                 value == ""
        ) {
            return true
        }
        else {
            return false;
        }
        
    } catch (error) {
        return false
    }
}

//function to spit back money in a format
export const money = (value, pense = 2) => {
    let moneyValue = value;

    if(moneyValue == ""){
        moneyValue = parseFloat(0);
    }

    if(typeof moneyValue == 'string'){
        moneyValue = replaceStringWith(moneyValue, ',', '');
    }

    
    var GBPFormatter = new Intl.NumberFormat("en-gb", {
        style: "currency",
        currency: "GBP",
        mininumFractionDigits: 0,
        maximumFractionDigits: pense,
    }).format(parseFloat(moneyValue).toFixed(2));
    return GBPFormatter;
};

export const convertMoneyToFloat = (value) => {
    let moneyValue = value;

    if(moneyValue == "" || typeof moneyValue == 'undefined'){
        moneyValue = parseFloat(0);
    }
    if(typeof moneyValue == 'string'){
        moneyValue = replaceStringWith(moneyValue, ',', '');
    }
    return moneyValue;
}

export const formatToHH = (value) => {
    const time = value.toLocaleString('en-gb', {
        minimumIntegerDigits: 6,
    })
    return time;
}

export const isClient = () => {
    return typeof window != "undefined" ? true : false;
};

// export const round = (num, decimals = 2) => {
//     const factor = Math.pow(10, decimals)
//     return Math.floor(num * factor) / factor;
// };

export const round = (num, decimals = 2) => {
    return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
};

export const vatPricing = (flag, tax, price, all_in_price = null, discount_value = false) => {
    let product_price;
    if(all_in_price != null){
        product_price = all_in_price;
    }
    else {
        product_price = price;
    }

    if(discount_value != false && discount_value > 0){
        product_price = round(product_price * discount_value)
    }
    const value = flag ? (1+tax/100) * product_price : product_price
    return money(value);
}

//get the lowest price for a particular product
export const lowestPrice = (product, discount) => {
    //check if there is price breaks
    let price;
    if (count(product.price_breaks) > 0) {
        //if yes then get the lowest one
        price = product.price_breaks.sort((a, b) => a.price - b.price)[0].price;
        // price = product.price_breaks.reverse()[0].price;
    } else {
        //if no then return the normal price
        if (discount) {
            price = product.discounted_price;
        } else {
            price = product.price;
        }
    }

    // return price.toFixed(2);
    return price;
};

export const hireDays = (deliveryTime, collectionTime) => {
    //Get 1 day in milliseconds
    const one_day = 1000 * 60 * 60 * 24;
    // Calculate the difference in milliseconds
    const difference_ms = collectionTime - deliveryTime;
    // Convert back to days check setting and return
    return Math.round(difference_ms / one_day) + 1;
};

export const validPostcode = (postcode) => {
    postcode = postcode.replace(/\s/g, "");
    // var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
    var regex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/gm;
    return regex.test(postcode);
};

export const formatPostcode = (postcode) => {
    var parts = postcode.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
    parts.shift();
    return `${parts[0]} ${parts[1]}`
}
export const validEmail = (email) => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};
export const validEmails = (str) => {
    // , ; space
    let email_checks = {
        failed_emails: [],
        checked_emails: []
    };
    const splitter = str?.split(/[\s,; ]+/)
    email_checks.checked_emails = splitter;
    splitter.forEach(email => {
        if(!validEmail(email)){
            email_checks.failed_emails.push(email);
        }
    })

    return email_checks;

}

export const formatEmails = (data) => {
    let uniqueEmails = new Set();
    data.forEach(entry => {
        const emails = entry.split(/[;,]+/).map(email => email.trim()).filter(email => email);

        emails.forEach(email => {
            uniqueEmails.add(email);
        });
    });
    return Array.from(uniqueEmails);
};

//wrap component in a container
export const wrapBoxLayout = (components) => {
    return (
        components &&
        components.map((component, index) => {
            return <Container key={index}>{component}</Container>;
        })
    );
};

//wrap the component in container with a specific width
export const wrapCustomWidthLayout = (components) => {
    return (
        components &&
        components.map((component, index) => {
            return (
                <Container style={{ maxWidth: layout_width }} key={index}>
                    {component}
                </Container>
            );
        })
    );
};

//wrap the component with a fragement tag, unique key is required
export const wrapWideLayout = (components) => {
    return (
        components &&
        components.map((component, index) => {
            return <Fragment key={index}>{component}</Fragment>;
        })
    );
};

//the three layouts are box, wide and custom they each have a custom function 
export const addLayout = (components, layout, layout_width = "0px") => {
    const layouts = {
        box: wrapBoxLayout,
        wide: wrapWideLayout,
        custom: wrapCustomWidthLayout,
    };
    const layoutMethod = layouts[layout];
    //the function is called and the components are in a specific layout
    const wrappedComponents = layoutMethod(components, layout_width);
    return wrappedComponents;
};

//get setting
export const getSetting = (template_settings, template_page_settings, type, default_value) => {
    //type is the key in the settings object
    //for example: if a page has a layout set then use page layout value, else if brand layout setting present use it else use as the default value sent as layout
    const layout = template_page_settings[type]
        ? template_page_settings[type]
        : template_settings[type]
        ? template_settings[type]
        : default_value;
    return layout;
};

export const uppercase = (val) => {
    return val.toUpperCase();
}

export const filterTimeSlots = (slots, duration) => {
    let filteredSlots = [];
    //get highest number in array
    let newEnd = slots.slice(-1).pop() - duration;
    //loop through slots and only get numbers lower or equal to new end
    slots.forEach(function(slot) {
      if(slot<=newEnd){
        filteredSlots.push(slot);
      }
    });
    return filteredSlots; 
}

export const filterEndSlots = (slots) => {
    let newSlots = [];
    let outsideWorkingHours = process.env.outsideWorkingHours
    let insideWorkingHours = process.env.insideWorkingHours

    outsideWorkingHours.some((timeslot) => {
        // if(!insideWorkingHours.includes(timeslot)){
            newSlots.push(timeslot)
        // }
    })
    return newSlots;
}

export const getEndSlot = (timeStart, duration) => {
    const end = parseInt(dayjs(timeStart, 'HH:mm').format('HH')) + parseInt(duration);
    let timeEnd;
    if(end>24){
      //check if end time will be more than 24
      timeEnd = dayjs(end-24, 'HH:mm').format('HH:mm');
    }else{
      timeEnd = dayjs(
          (parseInt(dayjs(timeStart, 'HH:mm').format('HH')) + parseInt(duration)),
          'HH:mm'
      ).format('HH:mm');
    }
    return timeEnd;
}

export const isString = (string) => {
    return typeof string === 'string' ? true : false;
}

export const baseLink = (link) => {
    return `/${link}`;
}

export const stripTags = (str) => { //TODO: strip replace tags, and then replace with Typography
    return str.replace( /(<([^>]+)>)/ig, '');
}

export const maxString = (str, limit) => {
    if(str.length > limit) {
        return str.substr(0, limit) + ' ...'
    }
    else {
        return str;
    }
}

export const getEndpoint = async (query) => {
    let returned = null;
    ['articles', 'casestudy', 'news', 'guide', 'blog'].some(el => {
        if(query.route.includes(el)){
            returned = {
              route: query.route,
              year: query.slug,
              month: query._slug_3,
              day: query._slug_4,
              slug: query._slug_5,
              page: !query.page ? 1 : query.page,
              per_page: query?.per_page || null
            }
        }
    }) 
    return returned;
}

export const articleLink = (type, article_date, article_slug) => {
    if(getBrands(['EH'])){
        return `/articles/${article_slug}`;
    }
    return '/' + (type == 'blogpost' ? 'blog' : type) + '/' + article_date.replaceAll('-', '/') + '/' + article_slug
}


export const countString = (str) => {
    const matches = str.match(/[\w\d\’\'-]+/gi);
    return matches ? matches.length : 0;
}

export const productLink = (product_section_slug, product_slug, long_term = false) => {
    let section_slug = product_section_slug;
    let is_long_term = false;
    let url = "";
    if(process.env.brand.brand_id == 1) {
        section_slug = 'product';
        is_long_term = long_term;
    }

    url = !is_long_term ? `/${section_slug}/${product_slug}` : `/long_term_hire/${product_slug}`;

    return url;
}

export const buildAddressToString = (addressData) => {
    const {
            Address1,
            Address2,
            Town,
            County,
            PostCode,
            Country = null,
            EmailAddress = null,
            CompanyName,
            ContactName = null,
            Telephone = null
        } = addressData;


    let arr = [];
    let companyField = CompanyName;
    const searchList = ['Address1', 'Address2', 'Town', 'County', 'PostCode']
    Object.keys(addressData).map((key) => {
        searchList.some(el => key.includes(el) && addressData[key] != null && addressData[key].trim() != "" && arr.push(addressData[key]))
    })

    let str = arr.toString();
    str = str.replaceAll(',', ', ');
    companyField.replaceAll('-', '');

    return (
        <span>
            <strong>{capitalizeFirstLetter(companyField)}</strong>
             - {str}
        </span>
    );

}

export const replaceStringWith = (string, toRemove, toReplace) => {
    try {
        let newStr = string.toString();
        return newStr.replaceAll(toRemove, toReplace)
    } catch (error) {
        return string?.toString()?.trim() || '';
    }
}

export const getHireDays = (from, to) => { //gets the hire days for the quote
    var one_day=1000*60*60*24;
    // Convert both dates to milliseconds
    var date1_ms = dayjs(from).valueOf();
    var date2_ms = dayjs(to).valueOf();
    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;
    // Convert back to days check setting and return
    const days = Math.round(difference_ms/one_day)+1; 


    // const days = b.diff(a, 'days')+1
    const weeks_ = Math.round((days / 7) + 0.35)
    const weeks = weeks_ <= 0 ? 1 : weeks_;
    const result = {days, weeks};
    return result;
}

export const handleFetch = async (request, allow_custom = false, custom_key = 'message') => {
    try {

        const response = await request;
        if(response.status == 200){
            return response.data
        }
        else {
            return {
                status: 'failed',
                message: allow_custom ? response.message : 'Oops, something went wrong!',
                data: []
            }
        }
            
    } catch (error) {
        const response = error.response;
        return {
            status: 'failed',
            message: allow_custom && response?.data?.[custom_key] ? response.data[custom_key] : 'Oops, something went wrong!',
            data: [],
        }
    }
}

export const renderTextField = ({ input, label, variant="outlined", meta: { touched, invalid, error }, ...custom },) => (
    <>
        <TextField
            helperText={touched && invalid && error}
            error={touched && invalid ? true : false}
            {...input}
            {...custom}
            variant={variant}
            label={custom.placeholder}
            className="w-100"
        />
    </>
);

export const renderTextFieldCustom = ({ input, label, variant="outlined", meta: { touched, invalid, error }, ...custom },) => (
    <>
        <TextInput
            helperText={touched && invalid && error}
            error={touched && invalid ? true : false}
            {...input}
            {...custom}
            variant={variant}
            label={custom.placeholder}
        />
    </>
);

export const renderSelectField = (
    { input, label, required, defaultValue, meta: { touched, invalid, error }, children, ...custom },
  ) => {
    const sxStyles = {
        color: '#d32f2f',
        fontFamily: 'Lato,Arial,sans-serif',
        fontWeight: '400',
        fontSize: '0.6428571428571428rem',
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        textAlign: 'left',
        marginTop: '3px',
        marginRight: '14px',
        marginBottom: '0',
        marginLeft: '14px'
    };

    const ITEM_HEIGHT = 80;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    return (
        <>
        <FormControl required={required} fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                className="w-100"
                // helpertext={touched && error}
                error={touched && invalid ? true : false}
            {...input}
            children={children}
            {...custom}
            label={label}
            MenuProps={MenuProps}

            />
        </FormControl>
        {touched && error && <FormHelperText sx={sxStyles}>{error}</FormHelperText>}
        </>

    )
  };

export const renderTextAreaField = (
    { input, label, meta: { touched, invalid, error }, ...custom },
  ) => (
    <>
        <TextField
            helperText={touched && invalid && error}
            error={touched && invalid ? true : false}
            {...input}
            {...custom}
            label={custom.placeholder}
            multiline
            rows={2}
            className="w-100"
        />

    </>
);


export const formatNewSlotDate = (oldSlot, newDate) => {
    //remove date XXXX-XX-XX 
    const stringWithoutDate = oldSlot.substr(10);

    //attach new date
    const newSlot = newDate + stringWithoutDate;
    return newSlot;
}

export const dateConverter = (date) => {
    return dayjs(date.split("/").reverse().join("-"));
}

export const base64Link = (url) => {
    return `data:image;base64,${url}`;
}

export const CreateArray = ({length, children}) => {
    return (
        Array.from(Array(length)).map((_, i) => (
            <Fragment key={i}>
                {children}
            </Fragment>
        ))
    )
}

export const getCustomerPaymentStatus = (PaymentReceived, PaymentReference, PaymentMethod, PaymentDate, PaymentTerms, POReference, values) => {

    let response = {
        message_long: '',
        message_short: '',
        color: '',
        hex_code: '',
        show_alert: false,
    };
    if(Number(PaymentReceived) == 1){
        let ref = "";
        if(typeof PaymentReference != 'undefined' && PaymentReference != null){
            if(PaymentMethod == 'CC'){
                ref = values.stripe_reference + ' on ' + PaymentDate;
              }
              else {
                ref = PaymentMethod + ' on ' + PaymentDate
            }
        }

        response.message_long = `Full payment received - main reference: ${ref}`;
        response.message_short = 'PAID';
        response.message_PDF = 'Paid';
        response.color = 'success';
        response.proforma = 0;
        response.hex_code = '#66bb6a';
        response.show_alert = true;
    }

    else if(Number(PaymentReceived) == 0 || PaymentReceived == null){
        if(PaymentTerms == 'Proforma' || PaymentTerms == null){
            response.message_long = 'Payment is proforma and full payment has not been received.';
            response.message_short = 'UNPAID';
            response.message_PDF = 'Due Now';
            response.color = 'error';
            response.proforma = 1;
            response.hex_code = '#f44336';
            response.show_alert = true;
        }

        else {
            if(PaymentTerms == 'OnAccount'){
                //WITH PO
                if(typeof POReference != 'undefined' && POReference != null && POReference != ""){
                    response.message_long = `Payment will be on account. Purchase order: ${values.purchase_order}`;
                    response.message_short = 'ACCOUNT (PO RECEIVED)';
                    response.message_PDF = 'Account (PO Received)';
                    response.color = 'advisory';
                    response.proforma = 0;
                    response.hex_code = '#ffd100';
                    response.show_alert = true;
                }
                // NO PO
                else {
                    response.message_long = `Payment will be on account. No purchase order has been supplied.`;
                    response.message_short = 'ACCOUNT (PO OUTSTANDING)';
                    response.message_PDF = 'Account (PO Outstanding)';
                    response.color = 'warning';
                    response.proforma = 0;
                    response.hex_code = '#ffa726';
                    response.show_alert = true;
                }
            }
        }
    }

    return response;

  }

  export const selectDuration = (amount, type) => { 
    switch (type) {
        case 'seconds':
            return (amount * 1000)
        case 'minutes':
            return (amount * 60 * 1000)
        case 'hours':
            const hours = (amount * 60);
            return (hours* 60 * 1000)
        case 'days':
            const days = (amount * 24);
            return (days * 60 * 60 * 1000)
    }
  }

export const buildDateQuery = (operator = '?', start_date, end_date) => {
    let str = operator;
    if(typeof start_date != 'undefined' && start_date != null){
        str += `start_date=${start_date}`;
    }
    if(typeof end_date != 'undefined' && end_date != null){
        str += `end_date=${end_date}`;
    }

    if(str == operator) str = "";

    return str;
}

export const getCategoryVisibleSons = (category) => {
    return category?.sons?.filter(son => son.visible == 'yes' && son.status == 'enabled');
}

export const isSafari = (userAgent) => {
    // Chrome and Chromium-based browsers include both 'Chrome' and 'Safari' in their userAgent strings
    const isChrome = userAgent?.includes("Chrome") || userAgent?.includes("Chromium");
    // Safari's userAgent? contains 'Safari' and does not include 'Chrome'
    const isSafariBrowser = userAgent?.includes("Safari") && !isChrome && userAgent?.includes("AppleWebKit");

    return isSafariBrowser;
}


export const convertToBlob = async (url) => {
    return await fetch(url)
        .then(res => res.blob())
        .then(blob => {
            return blob;
        })
}


export const calculateProductCosts = (products = []) => {
    let added_products = [];
    let order_product_weight = 0;
    let total_product_count = 0;

    let subtotal = 0; //product costs
    let product_total = 0; //product_total in database without discounts

    products.forEach((product, idx) => {

        const validatedProduct = product;
        const billed_weeks = product?.number_of_weeks_full == null ? validatedProduct.BilledWeeks : product.number_of_weeks_full;
        subtotal = subtotal + parseFloat(validatedProduct.UnitPrice) * parseInt(billed_weeks) * parseInt(validatedProduct.Quantity) ;
        // console.log({
        //     unit_price: validatedProduct,
        // })

        total_product_count = (total_product_count+parseInt(product.Quantity))
        // order_product_total = (order_product_total+parseFloat(product_total));
        order_product_weight = (order_product_weight+parseFloat(product.Weight * product.Quantity));
        let product_total_cost = validatedProduct.item_total_full == null ? validatedProduct.ItemTotal : validatedProduct.item_total_full;


        product_total = (parseFloat(product_total) + parseFloat(product_total_cost)).toFixed(2);

        added_products.push({
            ...validatedProduct,
            limitations: false,
        });
     
    });

    const result = {
        subtotal: subtotal,
        order_product_weight: order_product_weight,
        product_total: product_total,
        order_items: added_products,
        total_product_count: total_product_count,
    }

    // console.log(result)

    return result;
}

export const validateSortInput = (value) => {
    if (!value || value.trim() === "") return { string: "", error: false };

    const split_values = value.split(',')
        .map(item => item.trim())
        .filter(item => item !== '');

    const sort = [];
    let error = false;

    for (let values of split_values) {
        if (!values.includes(':')) {
            error = true;
            continue; 
        }

        const [key, val] = values.split(':').map(section => section.trim());

        if (!val || val.toLowerCase() !== 'asc' && val.toLowerCase() !== 'desc') {
            console.log("Invalid Val");
            error = true;
        } else {
            sort.push(`${key}: ${val}`);
        }
    }

    return {
        string: sort.join(', '),
        error: error
    }
}
export const getStatusTag = (status) => {
    let original = status.toLowerCase();
    let color = "";
    let type = "";

    switch (original) {
        case 'accepted (authorised)':
            type = "secondaryButton";
        break;
        case 'outstanding':
            type = "info";
        break;
        case 'lost':
            type = "danger"
        break;
        case 'accepted (pending authorisation)':
            type = "success";
        break;
    }

    return {
        color,
        type,
        status
    }
}

export const generateRandomString = () => {
    // c, k removed to prevent swears!!!!!!!!
    const letters = 'abdefghijlmnopqrstuvwxyz';
    
    let string = '';
    string += letters.charAt(Math.floor(Math.random() * letters.length));
    string += letters.charAt(Math.floor(Math.random() * letters.length));
    string += letters.charAt(Math.floor(Math.random() * letters.length));
    string += letters.charAt(Math.floor(Math.random() * letters.length));

    return string;
}

export const getQuoteStatusColour = (status) => {
    let color;
    switch (status) {
        case 'ACCEPTED (authorised)':
          color = "secondaryButton";
          break;
        case 'OUTSTANDING':
          color = "info"
          break;
        case 'LOST':
          color = "error"
          break;
        case 'ACCEPTED (pending authorisation)':
          color = "success"
          break;
        }
    return color;
}

export const calculateTotal = (values, key) => {
    const total = values.reduce((sum, current) => {
        return sum + round(number_format(current[key]));
      }, 0);

    return total;
}


export const calculateVAT = (total_ex_vat, base_vat = 20) => {

    const calculateVAT = () => {
        return base_vat/100;
    }
    const VAT = calculateVAT();
    const vat = parseFloat(Math.round(parseFloat(total_ex_vat)*VAT*100)/100).toFixed(2);
    const total_inc_vat = parseFloat(round(Number(total_ex_vat) + Number(vat))).toFixed(2);

    return {
        total_ex_vat: parseFloat(round(total_ex_vat)).toFixed(2),
        vat,
        total_inc_vat
    }

}

export const isFunction = (params) => {
    return typeof params == 'function';
}

export const isObject = (params) => {
    return typeof params == 'object';
}

export const replaceKeywords = (keywords, filtered_data, text) => {
    keywords?.map(keyword => {
        text = text.replaceAll(`[${keyword.email_template_keyword_key}]`, keyword[filtered_data[keyword.email_template_keyword_name]]);
    })

    return text;
}
   
export const replaceBrTags = (keywords = false, filtered_data, text, replaceWith = "") => {
    text = replaceStringWith(text, String.fromCharCode(13), replaceWith)
    text = replaceStringWith(text, String.fromCharCode(10), replaceWith)
    text = text.replaceAll(/(<br\ ?\/?>)+/g, replaceWith);
    text = text.replaceAll(/<p>\s*(<span[^>]*>&nbsp;<\/span>)\s*<\/p>/g, '');

    if(keywords != false){
        text = replaceKeywords(keywords, filtered_data, text);
    }

    return text;
}

export const buildColumnObject = (string) => {
    if(typeof string == 'undefined') return {};
    const array = string?.split(',') || [];
    let obj = {};
    for(const name of array){
        if(name != null && name != ""){
            obj[name.trim()] = false;
        }
    }

    return obj;
}

export const doesObjectHaveValues = (object, exclude_list = []) => {
    // checks if all object keys are null or empty string
    return Object.keys(object)
            .filter(key => !exclude_list.includes(key))
            .every(key => object[key] === "" || object[key] === null);
}

export const doesObjectHaveEmptyValues = (object, exclude_list = []) => {
    return Object.keys(object)
        .filter(key => !exclude_list.includes(key))
        .some(key => object[key] === "" || object[key] === null);
};


export const filterUniqueValues = (object, key, titleCallback = null, valueCallback = null) => {
    const stored_reasons = new Set();
    const filterReasons = Object.values(object).filter(item => {
        if(!stored_reasons.has(item[key])){
            stored_reasons.add(item[key])
            return true;
        }
        return false;
    }).map(item => ({
        title: isFunction(titleCallback) ? titleCallback(item[key]) : item[key],
        value: isFunction(valueCallback) ? valueCallback(item[key]) : item[key],
    }))

    filterReasons.unshift({title: 'All', value: ''})

    return filterReasons;
}

export const number_format = (value) => {
    let moneyString = replaceStringWith(value, ',', '');
        moneyString = parseFloat(moneyString).toFixed(2);
        return moneyString;
}

export const moneyFormatter = (params, key) => {
    let moneyString = params.row[key];
        return number_format(moneyString);
}

export const uniqueValues = (array) => {
    let unique = [];
    array?.forEach(element => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    });
    return unique
}

export const buildEmailAddresses = (emails) => {
    let emailsNormal = emails?.split(/[\s,; ]+/) || [];
    return [
        ...emailsNormal,
    ];

}

export const removeEmptyValues = (values) => {
    return values.filter(value => value != null && value != "");
}

export const getDefaultOfficeID = (user = null, defaultValue = 'all') => {
    if(user == null){
        return process.env.brand.office_id == 2 ? defaultValue : process.env.brand.office_id;
    }
}

export const getFooterName = (links, defaultValue) => {
    const link = links?.[0];
    if(!link || link == null) return defaultValue;

    return link?.footer_section_name || defaultValue;
}

export const getBrand = (checkBrand = 'FHUK', brand = process.env.brand.acronym) => {
    return checkBrand == brand;
}

export const getBrands = (checkBrand = ['FHUK'], brand = process.env.brand?.acronym) => {
    if (!brand) {
        console.warn("Brand is undefined or null");
        return false;
    }
    return checkBrand.includes(brand);
};

export const validateQty = (qty) => {
    if (Math.floor(qty) == qty && !isNaN(qty) && qty > 0) {
        return true;
    } else {
        return false;
    }
}

export const getSections = (object, prefix = "section_") => {
    const result = {};
    Object.keys(object).forEach((key) => {
      // Check if the key starts with the prefix
      if (key.startsWith(prefix)) {
        // Extract the base section name, e.g., "section_1" from "section_1_title"
        if(key.includes('image')){
            const sectionKey = key.replace('_image', '');
            result[sectionKey] = {
                ...result[sectionKey],
                image_alt: object[key].title,
                image: object[key].content
            }
        }
        else {
            result[key] = {
                ...result[key],
                ...object[key]
            }
        }
      }
    });
  
    return result;
}
