const actions = {
  CHECKOUT_PAYMENT_SET_PAYMENT: "CHECKOUT_PAYMENT_SET_PAYMENT",
  CHECKOUT_PAYMENT_SET_STEPS: "CHECKOUT_PAYMENT_SET_STEPS",
  CHECKOUT_PAYMENT_RESET: "CHECKOUT_PAYMENT_RESET",
  CHECKOUT_PAYMENT_SET_KEY: "CHECKOUT_PAYMENT_SET_KEY",
  CHECKOUT_PAYMENT_SET_LOADER: "CHECKOUT_PAYMENT_SET_LOADER",
  UPDATE_PAYMENT_OBJECT_KEY: "UPDATE_PAYMENT_OBJECT_KEY",
  PAYMENT_UPDATE_CRAFTY_RESULTS: "PAYMENT_UPDATE_CRAFTY_RESULTS",
  PAYMENT_SET_CRAFTY_SELECTED_ADDRESS: "PAYMENT_SET_CRAFTY_SELECTED_ADDRESS",
  CHECKOUT_PAYMENT_RESET: "CHECKOUT_PAYMENT_RESET",
  CHECKOUT_PAYMENT_GET_COUNTRIES: "CHECKOUT_PAYMENT_GET_COUNTRIES",
  CHECKOUT_PAYMENT_GET_COUNTRIES_SUCCESS: "CHECKOUT_PAYMENT_GET_COUNTRIES_SUCCESS",
  CHECKOUT_PAYMENT_GET_COUNTRIES_FAILURE: "CHECKOUT_PAYMENT_GET_COUNTRIES_FAILURE",
}

export default actions;
