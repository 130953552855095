import { combineReducers } from 'redux';
import globalReducer from "./Global/reducer";
import userReducer from "./User/reducer";
import categoryReducer from "./Category/reducer";
// import cartReducer from "./Cart/reducer";
// import detailsReducer from "./Details/reducer";
// import quoteReducer from "./Quote/reducer";
// import deliveryReducer from "./Delivery/reducer";
// import paymentReducer from "./Payment/reducer";
import modalsReducer from "./Modals/reducer";
import alertReducer from "./Alert/reducer";
import contactReducer from "./Contact/reducer";
import snackbarReducer from "./Snackbar/reducer";
import userjourneyReducer from "./UserJourney/reducer";
import trackingReducer from "./Tracking/reducer";
import stripeReducer from "./Stripe/reducer";
import payPalReducer from "./PayPal/reducer";
// import checkoutSuccessReducer from "./CheckoutSuccess/reducer";
import authReducer from "./Auth/reducer";
import agentReducer from "./Agent/reducer";
import dashboardReducer from "./Dashboard/reducer";
import jobsReducer from "./Jobs/reducer";
import cartManagementReducer from "./CartManagement/reducer";
import checkoutDetailsReducer from "./Checkout/Details/reducer";
import checkoutQuoteReducer from "./Checkout/Quote/reducer";
import checkoutDeliveryReducer from "./Checkout/Delivery/reducer";
import checkoutPaymentReducer from "./Checkout/Payment/reducer";
import checkoutSuccess2Reducer from "./Checkout/Success/reducer";

//Include all the reducer to combine and provide to configure store.
export const createReducer = (asyncReducers = {}) => combineReducers({
    ...asyncReducers,
    globalReducer,
    userReducer,
    categoryReducer,
    // cartReducer,
    // detailsReducer,
    // quoteReducer,
    // deliveryReducer,
    // paymentReducer,
    // checkoutSuccessReducer,
    modalsReducer,
    alertReducer,
    contactReducer,
    snackbarReducer,
    userjourneyReducer,
    trackingReducer,
    stripeReducer,
    payPalReducer,
    authReducer,
    agentReducer,
    dashboardReducer,
    jobsReducer,

    //new cart
    cartManagementReducer,
    checkoutDetailsReducer,
    checkoutQuoteReducer,
    checkoutDeliveryReducer,
    checkoutPaymentReducer,
    checkoutSuccess2Reducer,
})
export default createReducer;