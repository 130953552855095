import actions from '../Snackbar/actions';

const initialState = {
    open: false,
    title: null,
    variant: null,
    duration: 5000,
    vertical: 'bottom',
    horizontal: 'left'

}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SNACKBAR_SET:
      return {
        ...state,
        ...action.payload,
        duration: typeof action.payload.duration != 'undefined' ? action.payload.duration : 5000
    }
    case actions.SNACKBAR_RESET:
      return {
        ...state,
          open: false,
          title: null,
          variant: null,
    }

    default:
      return state
  }
}

export default Reducer;
