import actions from "../UserJourney/actions";

const initialState = {
    userJourney: {
        "user-journey-id": null,
        tracking: [],
        "utm-source": null,
        "utm-medium": null,
        "utm-campaign": null,
        "came-from": null,
    },
    userJourneyLoaders: {
        saveUserJourney: false,
        updateUserJourney: false,
        setUserJourney: false,
        insertUserJourneyEntry: false,
    },
};

function Reducer(state = initialState, action) {
    switch (action.type) {
        //set the loaders while the api request takes place
        case actions.USERJOURNEY_SAVE_DATA:
            return {
                ...state,
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    saveUserJourney: true,
                },
            };
        //when successful set the loaders to false and set the userJourney in the state using the api response data.
        case actions.USERJOURNEY_SAVE_DATA_SUCCESS:
            return {
                ...state,
                userJourney: {
                    ...state.userJourney,
                    ...action.payload,
                },
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    saveUserJourney: false,
                },
            };
        case actions.USERJOURNEY_SAVE_DATA_FAILURE:
            return {
                ...state,
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    saveUserJourney: false,
                },
            };
        //set the loaders to true
        case actions.USERJOURNEY_SET:
            return {
                ...state,
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    setUserJourney: true,
                },
            };

        //when successful set userJourney in state using userJourney in cache
        case actions.USERJOURNEY_SET_SUCCESS:
            return {
                ...state,
                userJourney: {
                    ...state.userJourney,
                    ...action.payload,
                },
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    setUserJourney: false,
                },
            };
        case actions.USERJOURNEY_SET_FAILURE:
            return {
                ...state,
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    setUserJourney: false,
                },
            };
        //set the loaders to true
        case actions.USERJOURNEY_ENTRY_SAVE_DATA:
            return {
                ...state,
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    insertUserJourneyEntry: true,
                },
            };
        case actions.USERJOURNEY_ENTRY_SAVE_DATA_SUCCESS:
            return {
                ...state,
                userJourney: {
                    ...state.userJourney,
                    tracking: action.payload,
                },
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    insertUserJourneyEntry: false,
                },
            };
        case actions.USERJOURNEY_ENTRY_SAVE_DATA_FAILURE:
            return {
                ...state,
                userJourneyLoaders: {
                    ...state.userJourneyLoaders,
                    insertUserJourneyEntry: false,
                },
            };
        default:
            return state;
    }
}

export default Reducer;
