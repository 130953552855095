const actions = {
  CHECKOUT_SUCCESS_SET_SUCCESS: "CHECKOUT_SUCCESS_SET_SUCCESS",
  CHECKOUT_SUCCESS_SET_KEY: "CHECKOUT_SUCCESS_SET_KEY",
  CHECKOUT_SUCCESS_SET_LOADING: "CHECKOUT_SUCCESS_SET_LOADING",
  CHECKOUT_SUCCESS_SET_CRAFTY_TYPE: "CHECKOUT_SUCCESS_SET_CRAFTY_TYPE",
  CHECKOUT_SUCCESS_SET_CHECKLIST_TYPE: "CHECKOUT_SUCCESS_SET_CHECKLIST_TYPE",
  CHECKOUT_SUCCESS_SET_ADDRESS_TYPE: "CHECKOUT_SUCCESS_SET_ADDRESS_TYPE",
  CLEAR_FOUND_ADDRESSES: "CLEAR_FOUND_ADDRESSES",
  CHECKOUT_SUCCESS_RESET: "CHECKOUT_SUCCESS_RESET",

  CHECKOUT_SUCCESS_GET_COUNTRIES: "CHECKOUT_SUCCESS_GET_COUNTRIES",
  CHECKOUT_SUCCESS_GET_COUNTRIES_SUCCESS: "CHECKOUT_SUCCESS_GET_COUNTRIES_SUCCESS",
  CHECKOUT_SUCCESS_GET_COUNTRIES_FAILURE: "CHECKOUT_SUCCESS_GET_COUNTRIES_FAILURE",

}

export default actions;
