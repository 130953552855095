import actions from '../../Checkout/Delivery/actions';

const initialState = {
    surcharges: [],
    transport: {
      delivery: {
        'install':'no',
        'installCost':0,
        'option':null,
        'timeSlots': process.env.insideWorkingHours,
        'timeStart':'08:00',
        'timeEnd':'18:00',
        'startReadOnly':true
      },
      collection: {
        'derig':'no',
        'derigCost':0,
        'option':null,
        'timeSlots': process.env.outsideWorkingHours,
        'timeStart':'08:00',
        'timeEnd':'18:00',
      }
    },
    deliveryLoaders: {
      deliveryInit: true,
      gettingSurcharges: false,
    },
    serviceLock: false,
    savingDelivery: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {

	case actions.DELIVERY_GET_SURCHARGES:
		return {
		  ...state, 
		  surcharges: [],
      deliveryLoaders: {
        ...state.deliveryLoaders,
        gettingSurcharges: true,
      }
		  
	  }
	case actions.DELIVERY_GET_SURCHARGES_SUCCESS:
		return {
		  ...state, 
      surcharges: action.payload,
      deliveryLoaders: {
        ...state.deliveryLoaders,
        gettingSurcharges: false,
      }
		  
	  }
	case actions.DELIVERY_GET_SURCHARGES_FAILURE:
		return {
		  ...state, 
		  surcharges: null,
      deliveryLoaders: {
        ...state.deliveryLoaders,
        gettingSurcharges: false,
      }
		  
	  }

    
    case actions.DELIVERY_GET_INSTALL_DERIG:
      return {
        ...state, 
        deliveryLoaders: {...state.deliveryLoaders, getInstallDerig: true, },
    }
    case actions.DELIVERY_GET_INSTALL_DERIG_SUCCESS:
      return {
        ...state, 
        transport: {
          ...state.transport,
          delivery: {
            ...state.transport.delivery,
            installCost: action.payload.install,
          },
          collection: {
            ...state.transport.collection,
            derigCost: action.payload.derig
          }
        },
        deliveryLoaders: { ...state.deliveryLoaders, getInstallDerig: false },
    }
    case actions.DELIVERY_GET_INSTALL_DERIG_FAILURE:
      return {
        ...state, 
        deliveryLoaders: { ...state.deliveryLoaders, getInstallDerig: false },
    }
    
	case actions.CHECKOUT_DELIVERY_SET_ALL:
		return {
		  ...state, 
		  ...action.payload
		  
	  }
	case actions.CHECKOUT_DELIVERY_SET_KEY:
		return {
		  ...state, 
		  transport: {
			...state.transport,
			[action.payload.key]: {
        ...state.transport[action.payload.key],
        ...action.payload.value
      }
		  }
	  }

	case actions.CHECKOUT_DELIVERY_SET_LOADER:
		return {
		  ...state, 
		 	 deliveryLoaders: {
				...state.deliveryLoaders,
				[action.payload.key]: action.payload.value
			},
	  }
    case actions.CHECKOUT_DELIVERY_RESET:
      	return initialState;
    default:
      	return state
  }
}

export default Reducer;
