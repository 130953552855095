import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../User/actions';
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, getBaseRequest} from '../../config/axiosClient'
import { filterTimeSlots, getEndSlot } from '../../config/helpers';
import CacheFactory from '../../Factories/CacheFactory';

export const getCart = (state) => state.cartReducer.cart;
export const getQuote = (state) => state.quoteReducer.quote;

function* initializeData(action) {
  try {
    const {isMobile, user_agent} = action.payload;
    const response = yield call(() => getBaseRequest('init-user'));
    const {ip, token} = response.data;

    const toInsert = {
      isMobile,
      user_agent,
      ip,
      token
    }

    yield put({type: actions.USER_INITIALIZE_DATA_SUCCESS, payload: toInsert});
  } catch (error) {
    yield put({type: actions.USER_INITIALIZE_DATA_FAILURE, payload: error});
    
  }
}

function* setToken(action) {
  try {
    let token = null;
    if(action.payload.requestType == 'fresh'){
      const response = yield call(() => getBaseRequest('get-token'));
      token = response.data.token;
      CacheFactory.put('token', token);
    }
    else {
      token = action.payload.token
    }
    yield put({type: actions.SET_TOKEN_SUCCESS, payload: token});
  } catch (error) {
    yield put({type: actions.SET_TOKEN_FAILURE, payload: null});
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.USER_INITIALIZE_DATA, initializeData)]);
  yield all([takeLatest(actions.SET_TOKEN, setToken)]);
}
